<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <!-- 卡显示表格 -->
        <div class="grid-content bg-purple">
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="产品(一)" name="first" v-if="false">
              <div class="user_box">
                <el-button style="margin-bottom: 20px;" @click="newCardsdialogVisible = true" icon="el-icon-plus">新增卡头
                </el-button>
                <el-button icon="el-icon-refresh" @click="getCards()">刷 新</el-button>
                <template>
                  <el-table @row-click="(row) => editCards(row)" size="small" :data="cardData" style="width: 100%"
                    height="610" max-height="800" highlight-current-row>
                    <!-- id -->
                    <el-table-column prop="id" v-if="false" width="120">
                    </el-table-column>
                    <!-- 是否可用 -->
                    <el-table-column prop="isuse" label="是否可用" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isuse === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isuse === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- Code -->
                    <el-table-column prop="pro_code" label="Code" width="120">
                    </el-table-column>
                    <!-- 卡编号 -->
                    <el-table-column prop="nums" label="卡编号" width="120">
                    </el-table-column>
                    <!-- 卡号段 -->
                    <el-table-column prop="card_bin" label="卡号段" width="100">
                    </el-table-column>
                    <!-- 卡组 -->
                    <el-table-column prop="organ" label="卡组" width="120">
                    </el-table-column>
                    <!-- 商户类别 -->
                    <el-table-column prop="supported_mcc_group" label="商户类别" width="100"> </el-table-column>
                    <!-- 所属地区 -->
                    <el-table-column prop="card_add" label="所属地区" width="100">
                    </el-table-column>
                    <!-- 卡类型 -->
                    <el-table-column prop="types" label="卡类型" width="120">
                    </el-table-column>
                    <!-- 卡币种 -->
                    <el-table-column label="卡币种" width="70" prop="moneytype">
                    </el-table-column>
                    <!-- 有效期 -->
                    <el-table-column prop="endyear" label="有效期" width="70"> </el-table-column>
                    <!-- 卡充值手续费 -->
                    <el-table-column prop="recharge_char" label="卡充值手续费" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.recharge_char }}%
                      </template>
                    </el-table-column>
                    <!-- 自定义账单地址 -->
                    <el-table-column prop="isbank" label="自定义账单地址" width="120">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isbank === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isbank === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 开卡费 -->
                    <el-table-column prop="service_char" label="开卡费" width="70">
                    </el-table-column>
                    <!-- 开卡功能 -->
                    <el-table-column prop="isaddcard" label="开卡功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isaddcard === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isaddcard === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 充值功能 -->
                    <el-table-column prop="isrechar" label="充值功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isrechar === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isrechar === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 转出功能 -->
                    <el-table-column prop="istoslide" label="转出功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.istoslide === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.istoslide === 2" type="info">
                          不支持
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 销卡功能 -->
                    <el-table-column prop="isopenremove" label="销卡功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isopenremove === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isopenremove === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 冻结功能 -->
                    <el-table-column prop="isdisables" label="冻结功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isdisables === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isdisables === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 开卡数量限制(每次) -->
                    <el-table-column prop="cardnum" label="开卡数量限制(每次)" width="130">
                    </el-table-column>
                    <!-- 最近更新时间 -->
                    <el-table-column prop="updatetime" label="最近更新时间" width="170">
                    </el-table-column>
                    <!-- 卡描述 -->
                    <el-table-column prop="descs" label="卡描述" width="313" :show-overflow-tooltip="true">
                    </el-table-column>
                    <!-- 操作 -->
                    <el-table-column label="操作" width="180">
                      <template slot-scope="scope">
                        <el-button @click.native.stop="editCards(scope.row)">编辑</el-button>
                        <el-button type="danger" @click.native.stop="deleteCards(scope.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[100,500,1000]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                  </el-pagination>
                </template>
              </div>
            </el-tab-pane>
            <el-tab-pane label="产品(二)" name="two" v-if="false">
              <div class="user_box">
                <el-button style="margin-bottom: 20px;" @click="GnewCardsdialogVisible = true" icon="el-icon-plus">新增卡头
                </el-button>
                <el-button icon="el-icon-refresh" @click="getGcards()">刷 新</el-button>
                <template>
                  <el-table @row-click="(row) => GeditCards(row)" size="small" :data="GcardData" style="width: 100%"
                    height="620" highlight-current-row>
                    <!-- id -->
                    <el-table-column prop="id" v-if="false" width="120">
                    </el-table-column>
                    <!-- 是否可用 -->
                    <el-table-column prop="isuse" label="是否可用" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isuse === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isuse === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 卡编号 -->
                    <el-table-column prop="cardarea" label="卡段" width="120">
                    </el-table-column>
                    <!-- 卡编号 -->
                    <el-table-column prop="nums" label="卡编号" width="120">
                    </el-table-column>
                    <!-- 卡类型 -->
                    <el-table-column prop="types" label="卡类型" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.types === 'share'" type="success">
                          共享卡
                        </el-tag>
                        <el-tag v-else-if="scope.row.types === 'recharge'" type="info">
                          常规卡
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 卡号段 -->
                    <el-table-column prop="card_bin" label="发卡机构识别号" width="120">
                    </el-table-column>
                    <!-- 卡组 -->
                    <el-table-column prop="organ" label="卡组" width="120">
                    </el-table-column>
                    <!-- 所属地区 -->
                    <el-table-column prop="card_add" label="所属地区" width="100">
                    </el-table-column>
                    <!-- 卡币种 -->
                    <el-table-column prop="moneytype" label="卡币种" width="70">
                    </el-table-column>
                    <!-- 有效期 -->
                    <el-table-column prop="endyear" label="有效期" width="70"> </el-table-column>
                    <!-- 卡充值手续费 -->
                    <el-table-column prop="recharge_char" label="卡充值手续费" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.recharge_char }}%
                      </template>
                    </el-table-column>
                    <!-- 自定义账单地址 -->
                    <el-table-column prop="isbank" label="自定义账单地址" width="120">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isbank === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isbank === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 开卡费 -->
                    <el-table-column prop="service_char" label="开卡费" width="70">
                    </el-table-column>
                    <!-- 开卡功能 -->
                    <el-table-column prop="isaddcard" label="开卡功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isaddcard === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isaddcard === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 充值功能 -->
                    <el-table-column prop="isrechar" label="充值功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isrechar === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isrechar === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 转出功能 -->
                    <el-table-column prop="istoslide" label="转出功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.istoslide === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.istoslide === 2" type="info">
                          不支持
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 销卡功能 -->
                    <el-table-column prop="isopenremove" label="销卡功能" width="80">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isopenremove === 1" type="success">
                          开启
                        </el-tag>
                        <el-tag v-else-if="scope.row.isopenremove === 2" type="danger">
                          关闭
                        </el-tag>
                      </template>
                    </el-table-column>
                    <!-- 开卡数量限制(每次) -->
                    <el-table-column prop="cardnum" label="开卡数量限制(每次)" width="130">
                    </el-table-column>
                    <!-- 最近更新时间 -->
                    <el-table-column prop="updatetime" label="最近更新时间" width="170">
                    </el-table-column>
                    <!-- 卡描述 -->
                    <el-table-column prop="descs" label="卡描述" width="313" :show-overflow-tooltip="true">
                    </el-table-column>
                    <!-- 操作 -->
                    <el-table-column label="操作" width="180">
                      <template slot-scope="scope">
                        <el-button @click.native.stop="GeditCards(scope.row)">编辑</el-button>
                        <el-button type="danger" @click.native.stop="deleteCards(scope.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination align='center' @size-change="GhandleSizeChange" @current-change="GhandleCurrentChange"
                    :current-page="GcurrentPage" :page-sizes="[100, 500, 1000]" :page-size="GpageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="Gtotal">
                  </el-pagination>
                </template>
              </div>
            </el-tab-pane>
            <!-- 产品(三) -->
            <el-tab-pane label="产品(一)" name="three">
              <div class="user_box">
                <el-button style="margin-bottom: 20px;" @click="dialogAdd_p = true" icon="el-icon-plus">
                  新增卡头
                </el-button>
                <el-button icon="el-icon-refresh" @click="pp_getGcards()">刷 新</el-button>
                <el-table @row-click="(row) => pEditcallback(row)" size="small" :data="pcardData" style="width: 100%"
                  height="620" highlight-current-row>
                  <!-- id -->
                  <el-table-column prop="id" v-if="false" width="120">
                  </el-table-column>
                  <!-- 是否可用 -->
                  <el-table-column prop="isuse" label="是否可用" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isuse === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isuse === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 卡编号 -->
                  <el-table-column prop="cardarea" label="卡段" width="120">
                  </el-table-column>
                  <!-- 卡编号 -->
                  <el-table-column prop="nums" label="卡编号" width="120">
                  </el-table-column>
                  <!-- 卡类型 -->
                  <el-table-column prop="types" label="卡类型" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.types === 'share'" type="success">
                        共享卡
                      </el-tag>
                      <el-tag v-else-if="scope.row.types === 'recharge'" type="info">
                        常规卡
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 卡组 -->
                  <el-table-column prop="organ" label="卡组" width="120">
                  </el-table-column>
                  <!-- 所属地区 -->
                  <el-table-column prop="card_add" label="所属地区" width="100">
                  </el-table-column>
                  <!-- 卡号段 -->
                  <el-table-column prop="card_bin" label="发卡机构识别号" width="120">
                  </el-table-column>
                  <!-- 卡币种 -->
                  <el-table-column prop="moneytype" label="卡币种" width="70">
                  </el-table-column>
                  <!-- 有效期 -->
                  <el-table-column prop="endyear" label="有效期" width="70"> </el-table-column>
                  <!-- 卡充值手续费 -->
                  <el-table-column prop="recharge_char" label="卡充值手续费" width="120">
                    <template slot-scope="scope">
                      {{ scope.row.recharge_char }}%
                    </template>
                  </el-table-column>
                  <!-- 自定义账单地址 -->
                  <el-table-column prop="isbank" label="自定义账单地址" width="180">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isbank === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isbank === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 开卡费 -->
                  <el-table-column prop="service_char" label="开卡费" width="70">
                  </el-table-column>
                  <!-- 开卡功能 -->
                  <el-table-column prop="isaddcard" label="开卡功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isaddcard === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isaddcard === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 充值功能 -->
                  <el-table-column prop="isrechar" label="充值功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isrechar === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isrechar === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 转出功能 -->
                  <el-table-column prop="istoslide" label="转出功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.istoslide === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.istoslide === 2" type="info">
                        不支持
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 销卡功能 -->
                  <el-table-column prop="isopenremove" label="销卡功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isopenremove === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isopenremove === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 锁卡功能 -->
                  <el-table-column prop="isdisables" label="冻结功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isdisables === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isdisables === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 开卡数量限制(每次) -->
                  <el-table-column prop="cardnum" label="开卡数量限制(每次)" width="130">
                  </el-table-column>
                  <!-- 最近更新时间 -->
                  <el-table-column prop="updatetime" label="最近更新时间" width="170">
                  </el-table-column>
                  <!-- 卡描述 -->
                  <el-table-column prop="descs" label="卡描述" width="313" :show-overflow-tooltip="true">
                  </el-table-column>
                  <!-- 操作 -->
                  <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                      <el-button @click.native.stop="pEditcallback(scope.row)">编辑</el-button>
                      <el-button type="danger" @click.native.stop="deleteCards(scope.row)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination align='center' @size-change="phandleSizeChange" @current-change="phandleCurrentChange"
                  :current-page="pcurrentPage" :page-sizes="[100, 500, 1000]" :page-size="ppageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="ptotal">
                </el-pagination>
              </div>
            </el-tab-pane>
            <!-- 产品(五) -->
            <el-tab-pane label="产品(二)" name="four">
              <div class="user_box">
                <el-button style="margin-bottom: 20px;" @click="openDialogAdd_Dog" icon="el-icon-plus">
                  新增卡头
                </el-button>
                <el-button icon="el-icon-refresh" @click="Dog_getGcards()">刷 新</el-button>
                <el-table @row-click="(row) => DogEditor(row)" size="small" :data="DogcardData" style="width: 100%"
                  height="620" highlight-current-row>
                  <!-- id -->
                  <el-table-column prop="id" v-if="false" width="120">
                  </el-table-column>
                  <!-- 是否可用 -->
                  <el-table-column prop="isuse" label="是否可用" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isuse === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isuse === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 卡编号 -->
                  <el-table-column prop="cardarea" label="卡段" width="120">
                  </el-table-column>
                  <!-- 卡编号 -->
                  <el-table-column prop="nums" label="卡编号" width="120">
                  </el-table-column>
                  <!-- 卡类型 -->
                  <!-- <el-table-column prop="types" label="卡类型" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.types === 'share'" type="success">
                        共享卡
                      </el-tag>
                      <el-tag v-else-if="scope.row.types === 'recharge'" type="info">
                        常规卡
                      </el-tag>
                    </template>
                  </el-table-column> -->
                  <!-- 卡组 -->
                  <el-table-column prop="organ" label="卡组" width="120">
                  </el-table-column>
                  <!-- 所属地区 -->
                  <el-table-column prop="card_add" label="所属地区" width="100">
                  </el-table-column>
                  <!-- 卡号段 -->
                  <el-table-column prop="card_bin" label="发卡机构识别号" width="120">
                  </el-table-column>
                  <!-- 卡币种 -->
                  <el-table-column prop="moneytype" label="卡币种" width="70">
                  </el-table-column>
                  <!-- 有效期 -->
                  <el-table-column prop="endyear" label="有效期" width="70"> </el-table-column>
                  <!-- 卡充值手续费 -->
                  <el-table-column prop="recharge_char" label="卡充值手续费" width="120">
                    <template slot-scope="scope">
                      {{ scope.row.recharge_char }}%
                    </template>
                  </el-table-column>
                  <!-- 自定义账单地址 -->
                  <el-table-column prop="isbank" label="自定义账单地址" width="180">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isbank === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isbank === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 开卡费 -->
                  <el-table-column prop="service_char" label="开卡费" width="70">
                  </el-table-column>
                  <!-- 开卡功能 -->
                  <el-table-column prop="isaddcard" label="开卡功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isaddcard === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isaddcard === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 充值功能 -->
                  <el-table-column prop="isrechar" label="充值功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isrechar === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isrechar === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 转出功能 -->
                  <el-table-column prop="istoslide" label="转出功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.istoslide === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.istoslide === 2" type="info">
                        不支持
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 销卡功能 -->
                  <el-table-column prop="isopenremove" label="销卡功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isopenremove === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isopenremove === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 锁卡功能 -->
                  <el-table-column prop="isdisables" label="冻结功能" width="80">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.isdisables === 1" type="success">
                        开启
                      </el-tag>
                      <el-tag v-else-if="scope.row.isdisables === 2" type="danger">
                        关闭
                      </el-tag>
                    </template>
                  </el-table-column>
                  <!-- 开卡数量限制(每次) -->
                  <el-table-column prop="cardnum" label="开卡数量限制(每次)" width="130">
                  </el-table-column>
                  <!-- 最近更新时间 -->
                  <el-table-column prop="updatetime" label="最近更新时间" width="170">
                  </el-table-column>
                  <!-- 卡描述 -->
                  <el-table-column prop="descs" label="卡描述" width="313" :show-overflow-tooltip="true">
                  </el-table-column>
                  <!-- 操作 -->
                  <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                      <el-button @click.native.stop="DogEditor(scope.row)">编辑</el-button>
                      <el-button type="danger" @click.native.stop="deleteCards(scope.row)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination align='center' @size-change="DoghandleSizeChange" @current-change="DoghandleCurrentChange"
                  :current-page="pcurrentPage" :page-sizes="[100, 500, 1000]" :page-size="DogageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="Dogtotal">
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <!-- 产品一新增卡 -->
    <el-dialog custom-class="ls" title="新增卡-产品(一)" :visible.sync="newCardsdialogVisible" width="60%" top="5vh">
      <el-form :label-position="labelPosition" label-width="80px" :model="newCard_form">
        <!-- 卡段 -->
        <el-form-item label="卡段">
          <el-input v-model="newCard_form.cardarea"></el-input>
        </el-form-item>
        <!-- 卡编号 -->
        <el-form-item label="卡编号">
          <el-input v-model="newCard_form.nums"></el-input>
        </el-form-item>
        <!-- 卡号段 -->
        <el-form-item label="卡号段">
          <el-input v-model="newCard_form.card_bin"></el-input>
        </el-form-item>
        <el-form-item label="Code">
          <el-input v-model="newCard_form.pro_code" placeholder="请输入产品(编码)，例如C30，C30就是电商盲盒卡的标识"></el-input>
        </el-form-item>
        <!-- 商户类别 -->
        <el-form-item label="商户类别">
          <el-input v-model="newCard_form.supported_mcc_group"></el-input>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡组织">
          <el-select v-model="newCard_form.organ" placeholder="请选卡类型">
            <el-option label="VISA" value="VISA"></el-option>
            <el-option label="MasterCard" value="MasterCard"></el-option>

          </el-select>
        </el-form-item>
        <!-- 所属地区 -->
        <el-form-item label="所属地区">
          <el-input v-model="newCard_form.card_add"></el-input>
        </el-form-item>
        <!-- 卡类型 -->
        <el-form-item label="卡类型">
          <el-input v-model="newCard_form.types"></el-input>
        </el-form-item>
        <!-- 卡币种 -->
        <el-form-item label="卡币种">
          <el-select v-model="newCard_form.moneytype" placeholder="请选卡币种">
            <el-option label="USD" value="840"></el-option>
          </el-select>
        </el-form-item>
        <!-- 有效期 -->
        <el-form-item label="有效期">
          <el-input v-model="newCard_form.endyear"></el-input>
        </el-form-item>
        <!-- 开卡费 -->
        <el-form-item label="开卡费">
          <el-input v-model="newCard_form.cardOpen"></el-input>
        </el-form-item>
        <!-- 卡充值手续费 -->
        <el-form-item label="卡充值手续费">
          <el-input v-model="newCard_form.cardRecharg"></el-input>
        </el-form-item>
        <!-- 是否可用 -->
        <el-form-item label="是否可用">
          <el-switch v-model="newCard_form.cardSatus" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡功能 -->
        <el-form-item label="开卡功能">
          <el-switch v-model="newCard_form.openCard" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 自定义账单 -->
        <el-form-item label="自定义账单">
          <el-switch v-model="newCard_form.isbank" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 充值功能 -->
        <el-form-item label="充值功能">
          <el-switch v-model="newCard_form.openRecharg" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 转出功能 -->
        <el-form-item label="转出功能">
          <el-switch v-model="newCard_form.openTransfer" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 销卡功能 -->
        <el-form-item label="销卡功能">
          <el-switch v-model="newCard_form.openPin" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 冻结功能 -->
        <el-form-item label="冻结功能">
          <el-switch v-model="newCard_form.oepnFrozen" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡数量限制(每次) -->
        <el-form-item label="开卡数量限制(每次)">
          <el-input v-model="newCard_form.openCardsSums"></el-input>
        </el-form-item>
        <!-- 卡描述 -->
        <el-form-item label="卡描述">
          <el-input type="textarea" :rows="4" v-model="newCard_form.cardDescribe"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newCardsdialogVisible = false">取 消</el-button>
        <el-button @click="newCardsdialogVisible = false, addCards()" v-no-more-click>确 定</el-button>
      </span>
    </el-dialog>
    <!-- 产品二新增卡 -->
    <el-dialog custom-class="ls" title="新增卡-产品(二)" :visible.sync="GnewCardsdialogVisible" width="60%">
      <el-form :label-position="labelPosition" label-width="80px" :model="GnewCard_form">
        <!-- 卡段 -->
        <el-form-item label="卡段">
          <el-input v-model="GnewCard_form.cardarea"></el-input>
        </el-form-item>
        <!-- 卡编号 -->
        <el-form-item label="卡编号">
          <el-input v-model="GnewCard_form.nums"></el-input>
        </el-form-item>
        <!-- 卡号段 -->
        <el-form-item label="卡号段">
          <el-input v-model="GnewCard_form.card_bin"></el-input>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡类型">
          <el-select v-model="GnewCard_form.types" placeholder="请选卡类型">
            <el-option label="share" value="share">共享卡</el-option>
            <el-option label="recharge" value="recharge">常规卡</el-option>
          </el-select>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡组织">
          <el-select v-model="GnewCard_form.organ" placeholder="请选卡类型">
            <el-option label="VISA" value="VISA"></el-option>
            <el-option label="MasterCard" value="MasterCard"></el-option>
            <el-option label="Discover" value="Discover"></el-option>
          </el-select>
        </el-form-item>
        <!-- 所属地区 -->
        <el-form-item label="所属地区">
          <el-input v-model="GnewCard_form.card_add"></el-input>
        </el-form-item>
        <!-- 卡币种 -->
        <el-form-item label="卡币种">
          <el-select v-model="GnewCard_form.moneytype" placeholder="请选卡币种">
            <el-option label="USD" value="USD"></el-option>
          </el-select>
        </el-form-item>
        <!-- 有效期 -->
        <el-form-item label="有效期">
          <el-input v-model="GnewCard_form.endyear"></el-input>
        </el-form-item>
        <!-- 开卡费 -->
        <el-form-item label="开卡费">
          <el-input v-model="GnewCard_form.cardOpen"></el-input>
        </el-form-item>
        <!-- 卡充值手续费 -->
        <el-form-item label="卡充值手续费">
          <el-input v-model="GnewCard_form.cardRecharg"></el-input>
        </el-form-item>
        <!-- 是否可用 -->
        <el-form-item label="是否可用">
          <el-switch v-model="GnewCard_form.cardSatus" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡功能 -->
        <el-form-item label="开卡功能">
          <el-switch v-model="GnewCard_form.openCard" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 自定义账单 -->
        <el-form-item label="自定义账单">
          <el-switch v-model="GnewCard_form.isbank" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 充值功能 -->
        <el-form-item label="充值功能">
          <el-switch v-model="GnewCard_form.openRecharg" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 转出功能 -->
        <el-form-item label="转出功能">
          <el-switch v-model="GnewCard_form.openTransfer" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 销卡功能 -->
        <el-form-item label="销卡功能">
          <el-switch v-model="GnewCard_form.openPin" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡数量限制(每次) -->
        <el-form-item label="开卡数量限制(每次)">
          <el-input v-model="GnewCard_form.openCardsSums"></el-input>
        </el-form-item>
        <!-- 卡描述 -->
        <el-form-item label="卡描述">
          <el-input type="textarea" :rows="4" v-model="GnewCard_form.cardDescribe"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="GnewCardsdialogVisible = false">取 消</el-button>
        <el-button @click="GnewCardsdialogVisible = false, GaddCards()" v-no-more-click>确 定</el-button>
      </span>
    </el-dialog>
    <!-- 产品三新增卡 -->
    <el-dialog custom-class="ls" title="新增卡-产品(三)" :visible.sync="dialogAdd_p" width="60%">
      <el-form :label-position="labelPosition" label-width="80px" :model="ppCard_form">
        <!-- 卡段 -->
        <el-form-item label="卡段">
          <el-input v-model="ppCard_form.cardarea"></el-input>
        </el-form-item>
        <!-- 卡编号 -->
        <el-form-item label="卡编号">
          <el-input v-model="ppCard_form.nums"></el-input>
        </el-form-item>
        <!-- 卡号段 -->
        <el-form-item label="卡号段">
          <el-input v-model="ppCard_form.card_bin"></el-input>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡类型">
          <el-select v-model="ppCard_form.types" placeholder="请选卡类型">
            <el-option label="share" value="share">共享卡</el-option>
            <el-option label="recharge" value="recharge">常规卡</el-option>
          </el-select>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡组织">
          <el-select v-model="ppCard_form.organ" placeholder="请选择卡组织">
            <el-option label="VISA" value="VISA"></el-option>
            <el-option label="MasterCard" value="MasterCard"></el-option>
          </el-select>
        </el-form-item>
        <!-- 所属地区 -->
        <el-form-item label="所属地区">
          <el-input v-model="ppCard_form.card_add"></el-input>
        </el-form-item>
        <!-- 卡币种 -->
        <el-form-item label="卡币种">
          <el-select v-model="ppCard_form.moneytype" placeholder="请选卡币种">
            <el-option label="USD" value="USD"></el-option>
          </el-select>
        </el-form-item>
        <!-- 有效期 -->
        <el-form-item label="有效期">
          <el-input v-model="ppCard_form.endyear"></el-input>
        </el-form-item>
        <!-- 卡充值手续费 -->
        <el-form-item label="卡充值手续费">
          <el-input v-model="ppCard_form.cardRecharg"></el-input>
        </el-form-item>
        <!-- 开卡费 -->
        <el-form-item label="开卡费">
          <el-input v-model="ppCard_form.service_char"></el-input>
        </el-form-item>
        <!-- 自定义账单 -->
        <el-form-item label="自定义账单">
          <el-switch v-model="ppCard_form.isbank" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 是否可用 -->
        <el-form-item label="是否可用">
          <el-switch v-model="ppCard_form.cardSatus" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡功能 -->
        <el-form-item label="开卡功能">
          <el-switch v-model="ppCard_form.openCard" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 充值功能 -->
        <el-form-item label="充值功能">
          <el-switch v-model="ppCard_form.openRecharg" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 转出功能 -->
        <el-form-item label="转出功能">
          <el-switch v-model="ppCard_form.openTransfer" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 销卡功能 -->
        <el-form-item label="销卡功能">
          <el-switch v-model="ppCard_form.openPin" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 锁卡功能 -->
        <el-form-item label="冻结功能">
          <el-switch v-model="ppCard_form.isopenremove" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡数量限制(每次) -->
        <el-form-item label="开卡数量限制(每次)">
          <el-input v-model="ppCard_form.openCardsSums"></el-input>
        </el-form-item>
        <!-- 卡描述 -->
        <el-form-item label="卡描述">
          <el-input type="textarea" :rows="4" v-model="ppCard_form.cardDescribe"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAdd_p = false">取 消</el-button>
        <el-button @click="dialogAdd_p = false, ppAddcards()" v-no-more-click>确 定</el-button>
      </span>
    </el-dialog>
    <!-- 产品五新增卡 -->
    <el-dialog custom-class="ls" :title="DogFormTitle" :visible.sync="dialogAdd_Dog" width="60%">
      <el-form :label-position="labelPosition" label-width="80px" :model="DogCard_form">
        <!-- 卡段 -->
        <el-form-item label="uid">
          <el-input v-model="DogCard_form.openid"></el-input>
        </el-form-item>
        <!-- 卡段 -->
        <el-form-item label="卡段">
          <el-input v-model="DogCard_form.cardarea"></el-input>
        </el-form-item>
        <!-- 卡编号 -->
        <el-form-item label="卡编号">
          <el-input v-model="DogCard_form.nums"></el-input>
        </el-form-item>
        <!-- 卡号段 -->
        <el-form-item label="卡号段">
          <el-input v-model="DogCard_form.card_bin"></el-input>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡组织">
          <el-select v-model="DogCard_form.organ" placeholder="请选择卡组织">
            <el-option label="VISA" value="VISA"></el-option>
            <el-option label="MasterCard" value="MasterCard"></el-option>
          </el-select>
        </el-form-item>
        <!-- 所属地区 -->
        <el-form-item label="所属地区">
          <el-input v-model="DogCard_form.card_add"></el-input>
        </el-form-item>
        <!-- 卡币种 -->
        <el-form-item label="卡币种">
          <el-select v-model="DogCard_form.moneytype" placeholder="请选卡币种">
            <el-option label="USD" value="USD"></el-option>
          </el-select>
        </el-form-item>
         <!-- 卡组织 -->
         <el-form-item label="卡类型">
          <el-select v-model="DogCard_form.types" placeholder="请选卡类型">
            <el-option label="share" value="share">共享卡</el-option>
            <el-option label="recharge" value="recharge">常规卡</el-option>
          </el-select>
        </el-form-item>
        <!-- 有效期 -->
        <el-form-item label="有效期">
          <el-input v-model="DogCard_form.endyear"></el-input>
        </el-form-item>
        <!-- 卡充值手续费 -->
        <el-form-item label="卡充值手续费">
          <el-input v-model="DogCard_form.recharge_char"></el-input>
        </el-form-item>
        <!-- 开卡费 -->
        <el-form-item label="开卡费">
          <el-input v-model="DogCard_form.service_char"></el-input>
        </el-form-item>
        <!-- 自定义账单 -->
        <el-form-item label="自定义账单">
          <el-switch v-model="DogCard_form.isbank" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
       
        <!-- 是否可用 -->
        <el-form-item label="是否可用">
          <el-switch v-model="DogCard_form.isuse" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡功能 -->
        <el-form-item label="开卡功能">
          <el-switch v-model="DogCard_form.isaddcard" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 充值功能 -->
        <el-form-item label="充值功能">
          <el-switch v-model="DogCard_form.isrechar" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 转出功能 -->
        <el-form-item label="转出功能">
          <el-switch v-model="DogCard_form.istoslide" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 销卡功能 -->
        <el-form-item label="销卡功能">
          <el-switch v-model="DogCard_form.isopenremove" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡数量限制(每次) -->
        <el-form-item label="开卡数量">
          <el-input v-model="DogCard_form.cardnum"></el-input>
        </el-form-item>
        <!-- 卡描述 -->
        <el-form-item label="卡描述">
          <el-input type="textarea" :rows="4" v-model="DogCard_form.descs"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAdd_Dog = false">取 消</el-button>
        <el-button @click="dialogAdd_Dog = false, Dogddcards()" v-no-more-click>确 定</el-button>
      </span>
    </el-dialog>

    <!-- 产品一编辑卡 -->
    <el-dialog custom-class="ls" title="编辑卡-产品(一)" :visible.sync="editCardsdialogVisible" width="60%">
      <el-form :label-position="labelPosition" label-width="80px" :model="editCard_form">
        <!-- 卡编号 -->
        <el-form-item label="卡编号">
          <el-input v-model="editCard_form.nums"></el-input>
        </el-form-item>
        <!-- 卡号段 -->
        <el-form-item label="卡号段">
          <el-input v-model="editCard_form.card_bin"></el-input>
        </el-form-item>
        <el-form-item label="Code">
          <el-input v-model="editCard_form.pro_code" placeholder="请输入产品(编码)，例如C30，C30就是电商盲盒卡的标识"></el-input>
        </el-form-item>
        <!-- 商户类别 -->
        <el-form-item label="商户类别">
          <el-input v-model="editCard_form.supported_mcc_group"></el-input>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡组织">
          <el-select v-model="editCard_form.organ" placeholder="请选择卡组织">
            <el-option label="VISA" value="VISA"></el-option>
            <el-option label="MasterCard" value="MasterCard"></el-option>
          </el-select>
        </el-form-item>
        <!-- 所属地区 -->
        <el-form-item label="所属地区">
          <el-input v-model="editCard_form.card_add"></el-input>
        </el-form-item>
        <!-- 卡类型 -->
        <el-form-item label="卡类型">
          <el-input v-model="editCard_form.types"></el-input>
        </el-form-item>
        <!-- 卡币种 -->
        <el-form-item label="卡币种">
          <el-select v-model="editCard_form.moneytype" placeholder="请选卡币种">
            <el-option label="USD" value="840">USD</el-option>
          </el-select>
        </el-form-item>
        <!-- 有效期 -->
        <el-form-item label="有效期">
          <el-input v-model="editCard_form.endyear"></el-input>
        </el-form-item>
        <!-- 卡充值手续费 -->
        <el-form-item label="卡充值手续费">
          <el-input v-model="editCard_form.cardRecharg"></el-input>
        </el-form-item>
        <!-- 开卡费 -->
        <el-form-item label="开卡费">
          <el-input v-model="editCard_form.cardOpen"></el-input>
        </el-form-item>
        <!-- 是否可用 -->
        <el-form-item label="是否可用">
          <el-switch v-model="editCard_form.cardSatus" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡功能 -->
        <el-form-item label="开卡功能">
          <el-switch v-model="editCard_form.openCard" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 自定义账单 -->
        <el-form-item label="自定义账单">
          <el-switch v-model="editCard_form.isbank" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 充值功能 -->
        <el-form-item label="充值功能">
          <el-switch v-model="editCard_form.openRecharg" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 转出功能 -->
        <el-form-item label="转出功能">
          <el-switch v-model="editCard_form.openTransfer" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 销卡功能 -->
        <el-form-item label="销卡功能">
          <el-switch v-model="editCard_form.openPin" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 冻结功能 -->
        <el-form-item label="冻结功能">
          <el-switch v-model="editCard_form.oepnFrozen" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡数量限制(每次) -->
        <el-form-item label="开卡数量限制(每次)">
          <el-input v-model="editCard_form.openCardsSums"></el-input>
        </el-form-item>
        <!-- 卡描述 -->
        <el-form-item label="卡描述">
          <el-input type="textarea" :rows="4" v-model="editCard_form.cardDescribe"></el-input>
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editCardsdialogVisible = false">取 消</el-button>
        <el-button @click="editCardsdialogVisible = false, editCardsed()" v-no-more-click>确 定</el-button>
      </span>
    </el-dialog>
    <!-- 产品二编辑卡 -->
    <el-dialog custom-class="ls" title="编辑卡-产品(二)" :visible.sync="GeditCardsdialogVisible" width="60%">
      <el-form :label-position="labelPosition" label-width="80px" :model="GeditCard_form">
        <!-- 卡段 -->
        <el-form-item label="卡段">
          <el-input v-model="GeditCard_form.cardarea"></el-input>
        </el-form-item>
        <!-- 卡编号 -->
        <el-form-item label="卡编号">
          <el-input v-model="GeditCard_form.nums"></el-input>
        </el-form-item>
        <!-- 卡号段 -->
        <el-form-item label="卡号段">
          <el-input v-model="GeditCard_form.card_bin"></el-input>
        </el-form-item>
        <!-- 卡类型 -->
        <el-form-item label="卡类型">
          <el-select v-model="GeditCard_form.types" placeholder="请选卡类型">
            <el-option label="share" value="share">共享卡</el-option>
            <el-option label="recharge" value="recharge">常规卡</el-option>
          </el-select>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡组织">
          <el-select v-model="GeditCard_form.organ" placeholder="请选择卡组织">
            <el-option label="VISA" value="VISA"></el-option>
            <el-option label="MasterCard" value="MasterCard"></el-option>
            <el-option label="Discover" value="Discover"></el-option>
          </el-select>
        </el-form-item>
        <!-- 所属地区 -->
        <el-form-item label="所属地区">
          <el-input v-model="GeditCard_form.card_add"></el-input>
        </el-form-item>
        <!-- 卡币种 -->
        <el-form-item label="卡币种">
          <el-select v-model="GeditCard_form.moneytype" placeholder="请选卡币种">
            <el-option label="USD" value="840">USD</el-option>
          </el-select>
        </el-form-item>
        <!-- 有效期 -->
        <el-form-item label="有效期">
          <el-input v-model="GeditCard_form.endyear"></el-input>
        </el-form-item>
        <!-- 卡充值手续费 -->
        <el-form-item label="卡充值手续费">
          <el-input v-model="GeditCard_form.recharge_char"></el-input>
        </el-form-item>
        <!-- 开卡费 -->
        <el-form-item label="开卡费">
          <el-input v-model="GeditCard_form.service_char"></el-input>
        </el-form-item>
        <!-- 是否可用 -->
        <el-form-item label="是否可用">
          <el-switch v-model="GeditCard_form.isuse" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡功能 -->
        <el-form-item label="开卡功能">
          <el-switch v-model="GeditCard_form.isaddcard" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 自定义账单 -->
        <el-form-item label="自定义账单">
          <el-switch v-model="GeditCard_form.isbank" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 充值功能 -->
        <el-form-item label="充值功能">
          <el-switch v-model="GeditCard_form.isrechar" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 转出功能 -->
        <el-form-item label="转出功能">
          <el-switch v-model="GeditCard_form.istoslide" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 销卡功能 -->
        <el-form-item label="销卡功能">
          <el-switch v-model="GeditCard_form.isopenremove" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡数量限制(每次) -->
        <el-form-item label="开卡数量限制(每次)">
          <el-input v-model="GeditCard_form.cardnum"></el-input>
        </el-form-item>
        <!-- 卡描述 -->
        <el-form-item label="卡描述">
          <el-input type="textarea" :rows="4" v-model="GeditCard_form.descs"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="GeditCardsdialogVisible = false">取 消</el-button>
        <el-button @click="GeditCardsdialogVisible = false, GeditCardsed()" v-no-more-click>确
          定</el-button>
      </span>
    </el-dialog>
    <!-- 产品3编辑卡 -->
    <el-dialog custom-class="ls" title="编辑卡-产品(三)" :visible.sync="dialog_p" width="60%">
      <el-form :label-position="labelPosition" label-width="80px" :model="peditCard_form">
        <!-- 卡段 -->
        <el-form-item label="卡段">
          <el-input v-model="peditCard_form.cardarea"></el-input>
        </el-form-item>
        <!-- 卡编号 -->
        <el-form-item label="卡编号">
          <el-input v-model="peditCard_form.nums"></el-input>
        </el-form-item>
        <!-- 卡号段 -->
        <el-form-item label="卡号段">
          <el-input v-model="peditCard_form.card_bin"></el-input>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡类型">
          <el-select v-model="peditCard_form.types" placeholder="请选卡类型">
            <el-option label="share" value="share">共享卡</el-option>
            <el-option label="recharge" value="recharge">常规卡</el-option>
          </el-select>
        </el-form-item>
        <!-- 卡组织 -->
        <el-form-item label="卡组织">
          <el-select v-model="peditCard_form.organ" placeholder="请选择卡组织">
            <el-option label="VISA" value="VISA"></el-option>
            <el-option label="MasterCard" value="MasterCard"></el-option>
          </el-select>
        </el-form-item>
        <!-- 所属地区 -->
        <el-form-item label="所属地区">
          <el-input v-model="peditCard_form.card_add"></el-input>
        </el-form-item>
        <!-- 卡币种 -->
        <el-form-item label="卡币种">
          <el-select v-model="peditCard_form.moneytype" placeholder="请选卡币种">
            <el-option label="USD" value="USD"></el-option>
          </el-select>
        </el-form-item>
        <!-- 有效期 -->
        <el-form-item label="有效期">
          <el-input v-model="peditCard_form.endyear"></el-input>
        </el-form-item>
        <!-- 卡充值手续费 -->
        <el-form-item label="卡充值手续费">
          <el-input v-model="peditCard_form.recharge_char"></el-input>
        </el-form-item>
        <!-- 开卡费 -->
        <el-form-item label="开卡费">
          <el-input v-model="peditCard_form.service_char"></el-input>
        </el-form-item>
        <!-- 是否可用 -->
        <!-- 自定义账单 -->
        <el-form-item label="自定义账单">
          <el-switch v-model="peditCard_form.isbank" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否可用">
          <el-switch v-model="peditCard_form.isuse" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡功能 -->
        <el-form-item label="开卡功能">
          <el-switch v-model="peditCard_form.isaddcard" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 充值功能 -->
        <el-form-item label="充值功能">
          <el-switch v-model="peditCard_form.isrechar" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 转出功能 -->
        <el-form-item label="转出功能">
          <el-switch v-model="peditCard_form.istoslide" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 销卡功能 -->
        <el-form-item label="销卡功能">
          <el-switch v-model="peditCard_form.isopenremove" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 锁卡功能 -->
        <el-form-item label="冻结功能">
          <el-switch v-model="peditCard_form.isdisables" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- 开卡数量限制(每次) -->
        <el-form-item label="开卡数量限制(每次)">
          <el-input v-model="peditCard_form.cardnum"></el-input>
        </el-form-item>
        <!-- 卡描述 -->
        <el-form-item label="卡描述">
          <el-input type="textarea" :rows="4" v-model="peditCard_form.descs"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_p = false">取 消</el-button>
        <el-button @click="dialog_p = false, pEditCards()" v-no-more-click>确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      DogFormTitle:"新增卡-产品(二)",
      total: 0,
      Gtotal: 0,
      ptotal: 0,
      Dogtotal: 0,
      currentPage: 1,
      pageSize: 100,
      ppageSize: 100,
      DogageSize: 100,
      GcurrentPage: 1,
      pcurrentPage: 1,
      dialogAdd_p: false,
      dialog_p: false,
      dialogAdd_Dog:false,
      GpageSize: 100,
      name: '产品管理',
      GnewCardsdialogVisible: false,
      newCardsdialogVisible: false,
      editCardsdialogVisible: false,
      GeditCardsdialogVisible: false,
      GnewCard_form: {},
      activeName: 'four',
      labelPosition: 'right',
      newCard_form: {},
      edId: '',
      GedId: '',
      GcardData: [],
      pcardData: [],
      DogcardData: [],
      ppCard_form: {
        types: 'recharge'
      },
      DogCard_form:{},
      editCard_form: {
        cardCurrency: '',
        cardDescribe: '',
        cardNum: '',
        cardOpen: '',
        cardRecharg: '',
        isuse: '',
        cardType: '',
        oepnFrozen: '',
        openCard: '',
        openCardsSums: '',
        openPin: '',
        openRecharg: '',
        openTransfer: '',
        isbank: '',
        nums: '',
        card_add: '',
        endyear: '',
        supported_mcc_group: '',
        card_bin: '',
        types: '',
        organ: '',
        moneytype: '',
        pro_code: '',
        cardSatus: '',
        organ: '',
        card_add: ''
      },
      GeditCard_form: {
        descs: '',
        cardNum: '',
        service_char: '',
        recharge_char: '',
        isuse: '',
        isaddcard: '',
        isopenremove: '',
        isrechar: '',
        istoslide: '',
        isbank: '',
        nums: '',
        card_add: '',
        endyear: '',
        card_bin: '',
        organ: '',
        moneytype: '',
        cardarea: '',
        cardnum: '',
        types: ''
      },
      peditCard_form: {
        id: '',
        nums: '',
        card_bin: '',
        moneytype: '',
        descs: '',
        types: '',
        endyear: '',
        service_char: '',
        recharge_char: '',
        isopenremove: '',
        isdisables: '',
        istoslide: '',
        isrechar: '',
        isaddcard: '',
        isuse: '',
        cardnum: '',
        card_add: '',
        organ: '',
        isbank: '',
        cardarea: ''
      },
      cardData: []
    }
  },
  methods: {
    // 获取老Dog在线产品
    getDogCardsOnline() {
      const result = this.$http.post('/api/v1/getDogpayCardsBin', {}, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then(res => {
        console.log("res ==> ", res);
      })
    },
    openDialogAdd_Dog(){
      this.DogCard_form = {}
      this.dialogAdd_Dog = true
      this.DogFormTitle = "新增卡-产品(二)"
    },
    // 页数
    handleSizeChange(val) {
      this.pageSize = val
      this.getCards()
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getCards()
    }, // 页数
    GhandleSizeChange(val) {
      this.GpageSize = val
      this.getGcards()
    },
    // 当前页
    GhandleCurrentChange(val) {
      this.GcurrentPage = val
      this.getGcards()
    },
    phandleSizeChange(val) {
      this.ppageSize = val
      this.pp_getGcards()
    },
    // 当前页
    phandleCurrentChange(val) {
      this.pcurrentPage = val
      this.pp_getGcards()
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0
      let t, y, m, d, h, i, s
      t = ts ? new Date(ts * 1000) : new Date()
      y = t.getFullYear()
      m = t.getMonth() + 1
      d = t.getDate()
      h = t.getHours()
      i = t.getMinutes()
      s = t.getSeconds()
      // 可依据须要在这里定义时间格式
      return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10

        ? '0' + d
        : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
    },
    // 产品一添加卡
    addCards() {
      let params = {
        cardarea: this.newCard_form.cardNum,
        types: this.newCard_form.cardType,
        moneytype: this.newCard_form.moneytype,
        descs: this.newCard_form.cardDescribe,
        service_char: this.newCard_form.cardOpen,
        recharge_char: this.newCard_form.cardRecharg,
        isdisables: this.newCard_form.oepnFrozen,
        isopenremove: this.newCard_form.openPin,
        istoslide: this.newCard_form.openTransfer,
        isrechar: this.newCard_form.openRecharg,
        isaddcard: this.newCard_form.openCard,
        isuse: this.newCard_form.cardSatus,
        cardnum: this.newCard_form.openCardsSums,
        isbank: this.newCard_form.isbank,
        card_add: this.newCard_form.card_add,
        nums: this.newCard_form.nums,
        cardarea: this.newCard_form.cardarea,
        endyear: this.newCard_form.endyear,
        card_bin: this.newCard_form.card_bin,
        supported_mcc_group: this.newCard_form.supported_mcc_group,
        types: this.newCard_form.types,
        organ: this.newCard_form.organ,
        pro_code: this.newCard_form.pro_code
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/addProduct', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '添加成功!',
            type: 'success'
          }), this.getCards()
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
    },
    DoghandleSizeChange(){
      this.DogageSize = val
      this.Dog_getGcards()
    },
    DoghandleCurrentChange(val){
      this.DogcurrentPage = val
      this.Dog_getGcards()
    },
    // 产品Dog获取卡列表
    Dog_getGcards(){
      let params = {
        page: this.pcurrentPage,
        limit: this.DogageSize
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getDproductList', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then(res => {
        console.log("res ==> ", res);
        this.DogcardData = res.data.data.data
        this.Dogtotal = res.data.data.total
        for (let i = 0; i < this.DogcardData.length; i++) {
          this.DogcardData[i].updatetime = this.getTime(this.DogcardData[i].updatetime)
        }
      }, (error) => {
        this.$message.error(error)
      })
    },
    // 产品3获取卡
    pp_getGcards() {
      let params = {
        page: this.pcurrentPage,
        limit: this.ppageSize
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getPinList', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.pcardData = res.data.data.data
        this.ptotal = res.data.data.total
        for (let i = 0; i < this.pcardData.length; i++) {
          this.pcardData[i].updatetime = this.getTime(res.data.data.data[i].updatetime)
        }
      }, (error) => {
        console.log(error)
      })
    },
    // 产品一编辑卡方法
    editCardsed() {
      if (this.editCard_form.moneytype == 'USD') {
        this.editCard_form.moneytype = '840'
      }
      let params = {
        id: this.edId,
        types: this.editCard_form.cardType,
        cardarea: this.editCard_form.cardNum,
        moneytype: this.editCard_form.moneytype,
        descs: this.editCard_form.cardDescribe,
        service_char: this.editCard_form.cardOpen,
        recharge_char: this.editCard_form.cardRecharg,
        isdisables: this.editCard_form.oepnFrozen,
        isopenremove: this.editCard_form.openPin,
        istoslide: this.editCard_form.openTransfer,
        isrechar: this.editCard_form.openRecharg,
        isaddcard: this.editCard_form.openCard,
        isuse: this.editCard_form.cardSatus,
        cardnum: this.editCard_form.openCardsSums,
        nums: this.editCard_form.nums,
        isbank: this.editCard_form.isbank,
        card_add: this.editCard_form.card_add,
        endyear: this.editCard_form.endyear,
        card_bin: this.editCard_form.card_bin,
        supported_mcc_group: this.editCard_form.supported_mcc_group,
        organ: this.editCard_form.organ,
        types: this.editCard_form.types,
        pro_code: this.editCard_form.pro_code
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/eidtProduct', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '修改成功!',
            type: 'success'
          }), this.getCards(), this.edId = ''
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
    },
    Dogddcards(){
      let params = this.DogCard_form
      if(this.DogCard_form.id){
        params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/eidtDproduct', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '编辑成功!',
            type: 'success'
          }), this.Dog_getGcards()
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
      }else{
        params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/addDproduct', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '添加成功!',
            type: 'success'
          }), this.Dog_getGcards()
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
      }
      
    },
    // 产品三添加卡
    ppAddcards() {
      let params = {
        cardarea: this.ppCard_form.cardarea,
        nums: this.ppCard_form.nums,
        card_bin: this.ppCard_form.card_bin,
        moneytype: this.ppCard_form.moneytype,
        descs: this.ppCard_form.cardDescribe,
        types: this.ppCard_form.types,
        endyear: this.ppCard_form.endyear,
        service_char: this.ppCard_form.service_char,
        recharge_char: this.ppCard_form.cardRecharg,
        isopenremove: this.ppCard_form.openPin,
        isdisables: this.ppCard_form.isopenremove,
        istoslide: this.ppCard_form.openTransfer,
        isrechar: this.ppCard_form.openRecharg,
        isaddcard: this.ppCard_form.openCard,
        isuse: this.ppCard_form.cardSatus,
        cardnum: this.ppCard_form.openCardsSums,
        organ: this.ppCard_form.organ,
        card_add: this.ppCard_form.card_add,
        isbank: this.ppCard_form.isbank
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/addPinpro', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '添加成功!',
            type: 'success'
          }), this.pp_getGcards()
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
    },
    // 产品一编辑卡回调
    editCards(row) {
      this.editCardsdialogVisible = true
      this.edId = row.id
      this.editCard_form.cardNum = row.cardarea
      this.editCard_form.organ = row.organ
      this.editCard_form.types = row.types
      this.editCard_form.moneytype = row.moneytype
      this.editCard_form.cardRecharg = row.recharge_char
      this.editCard_form.cardOpen = row.service_char
      this.editCard_form.cardSatus = row.isuse
      this.editCard_form.openCard = row.isaddcard
      this.editCard_form.openRecharg = row.isrechar
      this.editCard_form.openTransfer = row.istoslide
      this.editCard_form.openPin = row.isopenremove
      this.editCard_form.oepnFrozen = row.isdisables
      this.editCard_form.openCardsSums = row.cardnum
      this.editCard_form.cardDescribe = row.descs
      this.editCard_form.isbank = row.isbank
      this.editCard_form.nums = row.nums
      this.editCard_form.card_add = row.card_add
      this.editCard_form.endyear = row.endyear
      this.editCard_form.card_bin = row.card_bin
      this.editCard_form.supported_mcc_group = row.supported_mcc_group
      this.editCard_form.pro_code = row.pro_code
    },
    // 删除产品五

    // 删除卡
    deleteCards(row) {
      this.$confirm('是否删除此卡段?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id
        }
        params = this.$lmjm(params)
        const result = this.$http.post('/api/v1/delProduct', params, {
          headers: {
            token: window.localStorage.getItem('token')
          }
        })
        result.then((res) => {
          if (res.data.code == '200') {
            this.$message({
              message: '删除成功!',
              type: 'success'
            }), this.Dog_getGcards(), this.pp_getGcards()
          }
        }, (error) => {
          console.log(error)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 产品一获取卡
    getCards() {
      let params = {
        page: this.currentPage,
        limit: this.pageSize
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getProductList', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.cardData = res.data.data.data
        this.total = res.data.data.total
        for (let i = 0; i < this.cardData.length; i++) {
          this.cardData[i].updatetime = this.getTime(res.data.data.data[i].updatetime)
        }
      }, (error) => {
        console.log(error)
      })
    },
    // 产品二获取卡
    getGcards() {
      let params = {
        page: this.GcurrentPage,
        limit: this.GpageSize
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getGproductList', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.GcardData = res.data.data.data
        this.Gtotal = res.data.data.total
        for (let i = 0; i < this.GcardData.length; i++) {
          this.GcardData[i].updatetime = this.getTime(res.data.data.data[i].updatetime)
        }
      }, (error) => {
        console.log(error)
      })
    },
    // 产品二添加卡
    GaddCards() {
      let params = {
        cardarea: this.GnewCard_form.cardNum,
        moneytype: this.GnewCard_form.moneytype,
        descs: this.GnewCard_form.cardDescribe,
        service_char: this.GnewCard_form.cardOpen,
        recharge_char: this.GnewCard_form.cardRecharg,
        isopenremove: this.GnewCard_form.openPin,
        istoslide: this.GnewCard_form.openTransfer,
        isrechar: this.GnewCard_form.openRecharg,
        isaddcard: this.GnewCard_form.openCard,
        isuse: this.GnewCard_form.cardSatus,
        cardnum: this.GnewCard_form.openCardsSums,
        isbank: this.GnewCard_form.isbank,
        card_add: this.GnewCard_form.card_add,
        nums: this.GnewCard_form.nums,
        cardarea: this.GnewCard_form.cardarea,
        endyear: this.GnewCard_form.endyear,
        card_bin: this.GnewCard_form.card_bin,
        organ: this.GnewCard_form.organ,
        types: this.GnewCard_form.types
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/addGproduct', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '添加成功!',
            type: 'success'
          }), this.getGcards()
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
    },
    // 产品二编辑卡回调
    GeditCards(row) {
      this.GeditCardsdialogVisible = true
      this.GedId = row.id
      this.GeditCard_form.cardnum = row.cardnum
      this.GeditCard_form.organ = row.organ
      this.GeditCard_form.moneytype = row.moneytype
      this.GeditCard_form.recharge_char = row.recharge_char
      this.GeditCard_form.service_char = row.service_char
      this.GeditCard_form.isuse = row.isuse
      this.GeditCard_form.isaddcard = row.isaddcard
      this.GeditCard_form.isrechar = row.isrechar
      this.GeditCard_form.istoslide = row.istoslide
      this.GeditCard_form.isopenremove = row.isopenremove
      this.GeditCard_form.descs = row.descs
      this.GeditCard_form.isbank = row.isbank
      this.GeditCard_form.nums = row.nums
      this.GeditCard_form.card_add = row.card_add
      this.GeditCard_form.endyear = row.endyear
      this.GeditCard_form.card_bin = row.card_bin
      this.GeditCard_form.cardarea = row.cardarea
      this.GeditCard_form.types = row.types
    },
    // 产品二编辑卡方法
    GeditCardsed() {
      if (this.GeditCard_form.moneytype == '840') {
        this.GeditCard_form.moneytype = 'USD'
      }
      let params = {
        id: this.GedId,
        nums: this.GeditCard_form.nums,
        card_bin: this.GeditCard_form.card_bin,
        organ: this.GeditCard_form.organ,
        moneytype: this.GeditCard_form.moneytype,
        descs: this.GeditCard_form.descs,
        endyear: this.GeditCard_form.endyear,
        service_char: this.GeditCard_form.service_char,
        recharge_char: this.GeditCard_form.recharge_char,
        card_add: this.GeditCard_form.card_add,
        isbank: this.GeditCard_form.isbank,
        isopenremove: this.GeditCard_form.isopenremove,
        istoslide: this.GeditCard_form.istoslide,
        isrechar: this.GeditCard_form.isrechar,
        isaddcard: this.GeditCard_form.isaddcard,
        isuse: this.GeditCard_form.isuse,
        cardnum: this.GeditCard_form.cardnum,
        cardarea: this.GeditCard_form.cardarea,
        types: this.GeditCard_form.types
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/eidtGproduct', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '修改成功!',
            type: 'success'
          }), this.getGcards(), this.GedId = ''
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
    },
    // 产品五编辑卡
    DogEditor(row) {
      this.dialogAdd_Dog = true
      this.DogCard_form = row
      this.DogFormTitle = "编辑卡-产品(二)"
    },
    // 产品三编辑卡回调
    pEditcallback(row) {
      this.dialog_p = true
      this.peditCard_form.id = row.id
      this.peditCard_form.cardarea = row.cardarea
      this.peditCard_form.nums = row.nums
      this.peditCard_form.card_bin = row.card_bin
      this.peditCard_form.moneytype = row.moneytype
      this.peditCard_form.descs = row.descs
      this.peditCard_form.types = row.types
      this.peditCard_form.endyear = row.endyear
      this.peditCard_form.service_char = row.service_char
      this.peditCard_form.recharge_char = row.recharge_char
      this.peditCard_form.isopenremove = row.isopenremove
      this.peditCard_form.isdisables = row.isdisables
      this.peditCard_form.istoslide = row.istoslide
      this.peditCard_form.isrechar = row.isrechar
      this.peditCard_form.isaddcard = row.isaddcard
      this.peditCard_form.isuse = row.isuse
      this.peditCard_form.cardnum = row.cardnum
      this.peditCard_form.organ = row.organ
      this.peditCard_form.card_add = row.card_add
      this.peditCard_form.isbank = row.isbank
    },
    // 产品3编辑卡方法
    pEditCards() {
      let params = {
        id: this.peditCard_form.id,
        cardarea: this.peditCard_form.cardarea,
        nums: this.peditCard_form.nums,
        card_bin: this.peditCard_form.card_bin,
        moneytype: this.peditCard_form.moneytype,
        descs: this.peditCard_form.descs,
        types: this.peditCard_form.types,
        endyear: this.peditCard_form.endyear,
        service_char: this.peditCard_form.service_char,
        recharge_char: this.peditCard_form.recharge_char,
        isopenremove: this.peditCard_form.isopenremove,
        isdisables: this.peditCard_form.isdisables,
        istoslide: this.peditCard_form.istoslide,
        isrechar: this.peditCard_form.isrechar,
        isaddcard: this.peditCard_form.isaddcard,
        isuse: this.peditCard_form.isuse,
        cardnum: this.peditCard_form.cardnum,
        organ: this.peditCard_form.organ,
        card_add: this.peditCard_form.card_add,
        isbank: this.peditCard_form.isbank
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/eidtPinproduct', params, {
        headers: {
          token: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '修改成功!',
            type: 'success'
          }), this.pp_getGcards(), this.GedId = ''
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
    }
  },
  created() {
    // this.getCards()
    // this.getGcards()
    this.pp_getGcards()
    this.Dog_getGcards()
    this.getDogCardsOnline()
  },
  mounted() {

  }
}
</script>

<style scoped></style>
