const state = {
    userAuth: {}
  }
  
  /**
   * 单一赋值数据:修改对用的数据
   * 1.如果不需要对数据二次加工,直接存的话,可以调用此处的方法;
   * 2.如果需要对数据进行处理,二次加工之后,在存储的话,可以在 actions 内进行数据处理包装
   * 
   * 一、如何使用全局mutations
   * 示例1:
   * this.$store.commit('UPDATE_VERSION',newVersion)
   * 
   * 示例2:
   * 导包
   * import { mapMutations } from 'vuex'
   * methods 方法模块进行注册
   * methods: { 
   *    ...mapMutations(['UPDATE_VERSION']), // 保持原方法名不变,
   *    ...mapMutations({'updateVersion': 'UPDATE_VERSION'})// 在次组件中,新起一个名字
   * }
   * 直接使用
   * this.UPDATE_VERSION('')
   * this.updateVersion('')
   * 
   * 
   * 二、如何使用modules中的mutations（ namespaced:true）
   * 示例1:
   * this.$store.commit('settings/UPDATE_VERSION',newVersion)
   * 
   * 示例2:
   * import { mapMutations } from 'vuex'
   * methods 方法模块进行注册
   * methods: { 
   *    ...mapMutations('settings',['UPDATE_VERSION']), // 保持原方法名不变,
   *    ...mapMutations('settings',{'updateVersion': 'UPDATE_VERSION'})// 在次组件中,新起一个名字
   * }
   * 直接使用
   * this.UPDATE_VERSION('')
   * this.updateVersion('')
   * 
   * 
   */
  const mutations = {
    UPDATE_USERAUTH_OBJ: function (state, OBJ) { 
      state.userAuth = OBJ
    },
  }
  
  /**
   * 单一对数据进行加工之后,再进行存储,在此处进行处理之后,然后调用 mutations 内的方法
   * 
   * 一、如何使用全局 actions
   * 示例1:
   * this.$store.dispatch('settings/updateVersion','这是更新之后的版本号:V5.5.5')
   * 
   * 示例2:
   * 先导包
   * import { mapActions } from 'vuex'
   * 然后复制下面代码到 methods 模块进行注册
   * methods: { 
   *    ...mapActions(['updateVersion']), // 保持原方法名不变,
   *    ...mapActions({'newUpdateVersion': 'updateVersion'})// 在次组件中,新起一个名字
   * }
   * 直接使用
   * this.updateVersion('') // 对应保持原名称不变
   * this.newUpdateVersion('') // 对应新名称
   * 
   * 二、如何使用 modules 中的 actions namespaced:true）
   * 
   * 示例1:
   * this.$store.dispatch('settings/UPDATE_VERSION',newVersion)
   * 
   * 示例2:
   * import { mapActions } from 'vuex'
   * methods: { 
   *    ...mapActions('settings',['updateVersion']), // 保持原方法名不变,
   *    ...mapActions('settings',{'newUpdateVersion': 'updateVersion'})// 在次组件中,新起一个名字
   * }
   * 直接使用
   * this.updateVersion('') // 对应保持原名称不变
   * this.newUpdateVersion('') // 对应新名称
   * 
   */
  const actions = {
  
  
    updateUserAuth({ commit }, data) { 
      commit('UPDATE_USERAUTH_OBJ', data)
    },
  
  }
  
  /**
   * 一、如何使用全局getters
   * 示例1:
   * this.$store.getters.settingObj
   * 
   * 示例2:
   * import { mapGetters } from 'vuex'
   * 在 计算属性 computed 使用
   * computed: { 
   *    ...mapGetters(['updateVersion']), //保持原方法名不变,
   *    ...mapGetters({'newUpdateVersion': 'updateVersion'}) //在次组件中,新起一个名字
   * }
   * 
   * 二、如何使用 modules 中的 getters
   * 示例1:
   * this.$store.getters.settings.settingObj
   * 在 计算属性 computed 使用
   * computed: { 
   *    ...mapGetters('settings',['settingObj']), // 保持原方法名不变,
   *    ...mapGetters('settings',{'newSettingObj': 'settingObj'})// 在次组件中,新起一个名字
   * }
   */
  const getters = {
    userAuth: (state) => state.userAuth,
  }
  
  export default {
    // namespaced: true,
    state,
    mutations,
    actions,
    getters,
  }
  