import Vue from 'vue'
import Vuex from 'vuex'
import userAuth from './modules/userAuth'

Vue.use(Vuex)

// 全局的state
const state = {

}

// 全局的 mutations
const mutations = {

}

// 全局的 getters
const getters = {

}

const store = new Vuex.Store({
  state,
  mutations,
  getters,
  modules: {
    userAuth
    // 其他业务更多的模块文件
  },
})

export default store
