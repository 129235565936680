<template>
  <div class="container">
    <div class="db-RegisterAndLoginBackground-background">
      <div class="db-RegisterAndLoginBackground-grayBackground"></div>
      <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--first"></div>
      <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fifth"></div>
      <div class="db-RegisterAndLoginBackground-firstLeftStripe" style="background: rgb(128, 233, 255)"></div>
      <div class="db-RegisterAndLoginBackground-secondLeftStripe" style="background: rgb(122, 115, 255)"></div>
      <div class="db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend" style="background: rgb(0, 72, 229)">
      </div>
      <div class="db-RegisterAndLoginBackground-firstRightStripe" style="background: rgb(122, 115, 255)"></div>
    </div>
    <!-- 表单 -->
    <div class="db-RegisterAndLoginLayout-contentWrapper Box-root">
      <div class="db-RegisterAndLoginLayout-formContainer Box-root">
        <div class="db-RegisterAndLoginLayout-form-email">
          <!-- 登录表单 -->
          <div class="db-RegisterAndLoginLayout-form-email-row1">
            <img src="@/images/gmail.png" alt="" />
            <span style="margin-left: 15px">验证邮箱</span>
          </div>
          <!-- 验证码 -->
          <div class="db-RegisterAndLoginLayout-form-email-row3 martop--32">
            <div class="db-RegisterAndLoginLayout-form-email-row3-label">
              <span>请输入邮箱验证码</span>
            </div>
            <div class="db-RegisterAndLoginLayout-form-email-row3-code">
              <!-- <el-input style="margin-top: 12px;" v-model="form_reg.code"></el-input> -->
              <div class="verification-code-input">
                <Input v-for="(item, index) in codeLength" :key="index" class="code-input-item" maxlength="1"
                  @input="(event) => handleInput(event, index)" ref="codeInput" />
              </div>
              <div class="verification-code-send" @click="sendEmail">{{ countdown }}</div>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="db-RegisterAndLoginLayout-form-email-row4">
            <div class="db-RegisterAndLoginLayout-form-email-row4-btn" @click="emailLogin">
              确定
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      oathCode: "",
      codeLength: 6, // 设置验证码的长度
      username:'', // 用户名
      sendAble:false,
      countdown:'获取验证码',
      user:'', //
      send_page_code:"",
    };
  },
  methods: {
    // 邮箱登录
    emailLogin(){
      const params = {
          users: this.user,
          code: this.oathCode,
      }
      // params = this.$lmjm(params);
      const result = this.$http.post('/api/v1/emaillogin', params)
      result.then((res) => {
        if (res.data.code === 200) {
              let params = {};
              params = this.$lmjm(params);
              const result = this.$http.post("/api/v1/getuserinfos", params, {
                headers: {
                  token: res.data.data.token,
                },
              });
              result.then(
                (res) => {
                  // 获得成功响应返回的数据
                  Vue.prototype.$relv = res.data.data.relv;
                    Vue.prototype.$remoney = res.data.data.remoney;
                    Vue.prototype.$usd_disabled_mon = res.data.data.usd_disabled_mon;
                    if (res.data.data.usd < -2) {
                      MessageBox.alert("请缴纳您美金钱包内的负余额,或寻求帮助!", "系统提示", {
                        confirmButtonText: "确定",
                        showClose: false,
                        callback: (action) => {
                          next("/onlineRecharge");
                        },
                      });
                    }
                  // 获得成功响应返回的数据
                  this.$message({
                    message: "欢迎，" + res.data.data.realname + "。",
                    type: "success",
                    duration: "3000",
                  });
                },
                (error) => {
                  console.log(error);
                }
              );
              const resultAuth = this.$http.post("/api/v1/getAuth", {}, {
                  headers: {
                    token: res.data.data.token,
                  },
                });
                resultAuth.then(
                  (resAuth) => {
                    if (resAuth.data.code === 200) {
                      const token = res.data.data.token;
                      window.localStorage.setItem("token", token);
                      document.cookie = "isShow=true";
                      // this.$store.commit('UPDATE_USERAUTH_OBJ', resAuth.data.data)
                      if (resAuth.data.data.isreal == "1" || resAuth.data.data.isreal == "3") {
                        this.$router.push("/userAuth");
                      } else {
                        if (resAuth.data.data.types === 1 || resAuth.data.data.types === 4) {
                          this.$router.push("/home");
                        } else if (resAuth.data.data.types === 3) {
                          this.$router.push("/admin/home");
                        } else if (resAuth.data.data.types === 2) {
                          this.$router.push("/home");
                        }
                      }
                    }
                  },
                  (error) => {
                    console.log("错误:" + error);
                  }
                );
            } else {
              this.$message.error(res.data.msg);
            }
      })
    },
    // 发送邮箱
    sendEmail() {
      if(this.countdown != '获取验证码'){
        return
      }
      const params = {
          users: this.user,
          send_page_code: this.send_page_code,
      }
      const result = this.$http.post('/api/v1/sendloginemail', params)
      result.then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg)
          this.waitEmailTime()
        } else {
          this.$message.error(res.data.msg)
        }
      }, (error) => {
        this.$message.error('发送邮件失败!')
      })
      // })
    },
    // 等待5分钟重新发生邮箱
    waitEmailTime() {
      this.sendAble = true
      let countdownSeconds = 300; // 设置倒计时时间，单位为秒
      this.countdown = countdownSeconds; // 将倒计时时间存储到组件的属性中
      const countdownInterval = setInterval(() => {
        this.countdown--; // 每秒减少一秒
        if (this.countdown <= 0) {
          clearInterval(countdownInterval); // 倒计时结束，清除定时器
          this.sendAble = false;
          this.countdown = '获取验证码'
        }
      }, 1000); // 每秒执行一次
    },
    handleInput(event, index) {
      let inputValue = event.target.value;
      if (inputValue) {
        // 如果是正常输入
        if (this.oathCode.length === index) {
          if (
            this.oathCode.length < this.codeLength &&
            index + 1 < this.$refs.codeInput.length
          ) {
            this.$refs.codeInput[index + 1].focus();
          }
          this.oathCode += inputValue;
        } else {
          // 如果是修改操作
          this.oathCode =
            this.oathCode.slice(0, index) +
            inputValue +
            this.oathCode.slice(index + 1);
        }
      } else {
        // 如果是删除操作
        if (this.oathCode.length > index && index > 0) {
          this.oathCode =
            this.oathCode.slice(0, index) + this.oathCode.slice(index + 1);
          this.$nextTick(() => {
            if (this.$refs.codeInput[index - 1]) {
              this.$refs.codeInput[index - 1].focus();
            }
          });
        }
      }
      // this.form.emailCode = this.oathCode;
      // if (this.oathCode.length === this.codeLength) {
      //   // 这里是判断输入验证码为6位数完成之后的操作，例如注册、登录逻辑处理
      // }
    },
    loadCSS(value) {
      this.removeCSS(value);
      var cssLink = document.createElement("link");
      cssLink.rel = "stylesheet";
      cssLink.type = "text/css";
      // cssLink.href = `/register/theme/${value}/index.css`;
      cssLink.href = this.$csslink + `/theme/${value}/index.css`;
      cssLink.id = `theme-css-${value}`; // 设置唯一 ID
      document.getElementsByTagName("head")[0].appendChild(cssLink);
    },
    removeCSS(themeName) {
      var link = document.getElementById(`theme-css-${themeName}`);
      if (link) {
        link.parentNode.removeChild(link);
      }
    },
    go_login() {
      this.$router.push("/login");
    },
    // 判断ip
    getChinaIp() {
      // 判断用户IP
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getipaddress", params, {
        headers: {},
      });
      result.then(
        (res) => {
          if (res.data.data.in_china == 1 && res.data.data.iplimit == 1) {
            window.open("/error.html", "_self");
            // router.push('/login')
          } else {
            this.$router.push("/register");
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
  },
  mounted() {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("theme", "light");
    this.loadCSS("light");
  },
  created() { 
    this.user = this.$cookie.get("user");
    this.send_page_code = this.$cookie.get("send_page_code");
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  min-height: 100%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  box-sizing: border-box;
}

.db-RegisterAndLoginBackground-background {
  z-index: 0;
  scrollbar-gutter: stable;
  width: 100vw;
  position: fixed;
  top: -250px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: skewY(-12deg);
}

.db-RegisterAndLoginBackground-grayBackground {
  transform-origin: 0%;
  height: 1698px;
  background-color: rgb(247, 250, 252);
  position: absolute;
  top: -1000px;
  left: 0;
  right: 0;
}

.db-RegisterAndLoginBackground-verticalLine--first {
  left: calc(50% - 540px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth,
.db-RegisterAndLoginBackground-verticalLine--first {
  background: #eceef4;
}

.db-RegisterAndLoginBackground-verticalLine {
  width: 1px;
  background-image: linear-gradient(#eceef4 49%, #eceef400 50%);
  background-size: 1px 8px;
  position: absolute;
  top: -100vh;
  bottom: -100vh;
  right: auto;
}

.db-RegisterAndLoginBackground-verticalLine--second {
  left: calc(50% - 270px);
}

.db-RegisterAndLoginBackground-verticalLine--third {
  left: 50%;
}

.db-RegisterAndLoginBackground-verticalLine--fourth {
  left: calc(50% + 269px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth {
  left: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth,
.db-RegisterAndLoginBackground-verticalLine--first {
  background: #eceef4;
}

.db-RegisterAndLoginBackground-firstLeftStripe {
  height: 40px;
  top: 668px;
  left: calc(50% - 700px);
  right: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend,
.db-RegisterAndLoginBackground-firstLeftStripe,
.db-RegisterAndLoginBackground-firstRightStripe,
.db-RegisterAndLoginBackground-secondLeftStripe {
  position: absolute;
}

.db-RegisterAndLoginBackground-secondLeftStripe {
  height: 40px;
  top: 698px;
  left: -10px;
  right: calc(50% + 405px);
}

.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend {
  height: 10px;
  top: 698px;
  left: calc(50% - 700px);
  right: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-firstRightStripe {
  height: 40px;
  top: 658px;
  left: calc(50% + 430px);
  right: -10px;
}

.Box-root {
  box-sizing: border-box;
}

.db-RegisterAndLoginLayout-logo {
  padding-bottom: 40px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 900;
}

.Icon-color--gray800 {
  color: rgb(42, 47, 69);
}

.db-RegisterAndLoginLayout-contentWrapper {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.db-RegisterAndLoginLayout-formContainer {
  padding-top: 15%;
  width: 640px;
  min-width: 540px;
  display: flex;
  flex-direction: column;
}

.db-RegisterAndLoginLayout-form-email {
  position: relative;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  transform: translate3d(0px, 0px, 0);
  transform-origin: 0% 0%;
  width: 100%;
  min-width: 540px;
  height: 500px;
  will-change: transform, width, height;
  box-sizing: border-box;
  padding: 16px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.db-RegisterAndLoginLayout-form-email-row1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // margin-top: 15px;
  // margin-left: 20px;
  font-family: sohne-var, sohne-woff, Helvetica Neue, Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.2px;
  color: rgb(60, 66, 87);
  margin-bottom: 40px;
}

.db-RegisterAndLoginLayout-form-email-row3 {
  // margin-top: 15px;
  // margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgb(60, 66, 87);
  width: 100%;
}

.db-RegisterAndLoginLayout-form-email-row3-code {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.db-RegisterAndLoginLayout-form-email-row5 {
  margin-top: 20px;
  margin-left: 20px;
}

.db-RegisterAndLoginLayout-form-email-row4 {
  margin-top: 8px;
  margin-left: 20px;
}

.db-RegisterAndLoginLayout-form-email-row4 {
  width: 100%;
}

.verification-code-send {
  cursor: pointer;
  width: 15%;
  color: #000;
  text-align: center;
  border: 2px solid #000;
  height: 50px;
  line-height: 50px;
  border-radius: 8px;
}

.verification-code-input {
  display: flex;
  justify-content: space-around;
  width: 85%;
  //  margin-top: 34px;

  /deep/.ivu-input {
    width: 74px;
    height: 74px;
    font-size: 32px;
    font-weight: bold;
    padding: 27px;
  }

  .code-input-item {
    max-width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    //  width: 60px;
    //  height: 60px;
    font-size: 20px;
    background-color: #f2f4f5;
    border: none;
  }
}

.db-RegisterAndLoginLayout-form-email-row4-btn {
  margin-top: 40px;
  cursor: pointer;
  // margin-top: 8px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
  background-color: rgb(99, 91, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(69, 56, 255, 0.8) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
}

.db-RegisterAndLoginLayout-form-email-row4-btn:hover {
  transform: translateY(-3px);
}

.db-RegisterAndLoginLayout-form-email-row4-btn:active {
  transform: translateY(3px);
}

.martop--32 {
  // margin-top: 32px;
}

.db-RegisterAndLoginLayout-foot {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 98%;
  height: 50px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: #f6f9fc;
}

.btn_foot {
  cursor: pointer;
  font-weight: 600;
  color: #635bff;
  transition: all 0.1s linear;
}

.btn_foot:hover {
  color: #000;
}
</style>
