<template>
  <el-main>
    <div class="title_tab">
      {{ name }}
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="user_box">
            <el-form :model="form_balance" :rules="form_balanceRules" ref="form_balanceFormRef"
              :label-position="labelPosition">
              <!-- 输入卡号 -->
              <el-input v-model="form_balance.users" prefix-icon="el-icon-tickets" placeholder="请输入用户名" clearable @change="searchUser">
              </el-input>
              <el-input v-model="form_balance.keywords" prefix-icon="el-icon-tickets" placeholder="请输入关键字" clearable @change="searchUser">
              </el-input>
              <!-- <el-button icon="el-icon-search" @click="searchUser()">搜 索</el-button>
              <el-button icon="el-icon-refresh" @click="searchUser('rf')">刷 新</el-button> -->
            </el-form>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table :data="tableData" style="width: 100%" height="620" max-height="800" size="small"
              highlight-current-row>
              <el-table-column label="用户名 " prop="users"> </el-table-column>
              <el-table-column label="卡号" prop="card_num"> </el-table-column>
              <el-table-column label="商户名" prop="shopname">
              </el-table-column>
              <el-table-column label="黑名单词" prop="keywords"></el-table-column>
              <el-table-column label="备注" prop="remark">
                <template slot-scope="scope">
                    <el-tag type="danger" v-if="scope.row.remark == '高危'">{{ scope.row.remark }}</el-tag>
                    <el-tag type="warning" v-if="scope.row.remark == '中危'">{{ scope.row.remark }}</el-tag>
                    <el-tag type="info" v-if="scope.row.remark == '低危'">{{ scope.row.remark }}</el-tag>
                  </template>
              </el-table-column>
              <el-table-column label="记录时间 " prop="createtime"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      gshareusdmoney: "",
      pshareusdmoney: "",
      cny: "",
      usd: "",
      name: "异常数据",
      total: 0,
      labelPosition: "right",
      selectVlues: "",
      selelctRechargeStatusVal: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      dataTimes: [],
      form_balance: {
        cardNo: "",
      },
      form_balanceRules: {},
      // 支付状态
      selelctRechargeStatus: [
        {
          value: "1",
          label: "成功",
        },
        {
          value: "2",
          label: "失败",
        },
      ],
      // 选择事件类型
      options: [
        {
          value: "1",
          label: "充值",
        },
        {
          value: "2",
          label: "卡转出",
        },
        {
          value: "3",
          label: "开卡费",
        },
        {
          value: "4",
          label: "销卡转入",
        },
        // {
        //   value: "5",
        //   label: "撤销转入",
        // },
        {
          value: "6",
          label: "网关失败",
        },
        {
          value: "7",
          label: "跨境手续费",
        },
        // {
        //   value: "8",
        //   label: "补缴手续费",
        // },
        // {
        //   value: "9",
        //   label: "退款手续费",
        // },
        {
          value: "10",
          label: "交易手续费",
        },
        {
          value: "11",
          label: "广告费支付",
        },
        {
          value: "12",
          label: "广告费退回",
        },
      ],
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    getCommonNum() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCommonNum", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cny = res.data.data.cnycount;
          this.usd = res.data.data.usdcount;
          this.pshareusdmoney = res.data.data.pshareusdmoney;
          this.gshareusdmoney = res.data.data.gshareusdmoney;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 搜索
    searchUser(rf) {
      if (rf == "rf") {
        this.currentPage = 1;
        this.pageSize = 100;
        this.form_balance.users = "";
        this.form_balance.keywords = "";
        this.selectVlues = "";
        this.selelctRechargeStatusVal = "";
        this.dataTimes = [];
      }
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = "";
        this.dataTimes[1] = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        users: this.form_balance.users,
        keywords: this.form_balance.keywords,
        event_type: this.selectVlues,
        pay_type: this.selelctRechargeStatusVal,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getBlacklistLog", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.searchUser();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchUser();
    },
  },
  created() {
    this.searchUser();
  },
  mounted() { },
};
</script>

<style scoped>
.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}
</style>
