<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('eCommerceCustomerAcquisition.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('eCommerceCustomerAcquisition.title1') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <div class="header_btn_group">
              <!-- 操作事件 -->
              <el-input style="width: 200px" v-model="query"
                :placeholder="$t('eCommerceCustomerAcquisition.des1')">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="searchData" ></i>
              </el-input>
            </div>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table :data="tableData" style="width: 100%" height="660" max-height="700" size="small"
              highlight-current-row>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.taskStatus')" align="center" fixed="left"
                width="140" prop="status_run">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status_run == 0" type="warning">
                    {{ $t('eCommerceCustomerAcquisition.didnTStart') }}
                  </el-tag>
                  <div class="openRunImage_box" v-if="scope.row.status_run == 1" @click="viewResultImg">
                    <img src="@/images/svg/adv/run.svg" alt="" srcset="" />
                    <el-image ref="previewImg" style="width: 60px; height: 60px" v-show="false"
                      :src="scope.row.resultUrl[0]" :preview-src-list="scope.row.resultUrl">
                    </el-image>
                    {{ $t('eCommerceCustomerAcquisition.screenshotOfTheTask') }}
                  </div>
                  <el-tag v-if="scope.row.status_run == 2" type="danger">
                    {{ $t('eCommerceCustomerAcquisition.pause') }}
                  </el-tag>
                  <el-tag v-if="scope.row.status_run == -1" type="danger">
                    {{ $t('eCommerceCustomerAcquisition.comeToAnEnd') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.passwordIdentificationNumber')" align="center"
                width="140" prop="adv_sn">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.deliveryMode')" align="center" width="140"
                prop="push_type">
                <template slot-scope="scope">
                  <div v-if="scope.row.push_type == 1">{{ $t('eCommerceCustomerAcquisition.flowRate') }}</div>
                  <div v-if="scope.row.push_type == 2">{{ $t('eCommerceCustomerAcquisition.sales') }}</div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.theTypeOfImpression')" align="center"
                width="140" prop="push_target">ow_type">
                <template slot-scope="scope">
                  <div v-if="scope.row.show_type == 1">{{ $t('eCommerceCustomerAcquisition.site') }}</div>
                  <div v-if="scope.row.show_type == 2">{{ $t('eCommerceCustomerAcquisition.informationApplications') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.targetStrategy')" align="center" width="140"
                prop="target">
                <template slot-scope="scope">
                  <div v-if="scope.row.target == 1">{{ $t('eCommerceCustomerAcquisition.maximizeConversions') }}</div>
                  <div v-if="scope.row.target == 2">{{ $t('eCommerceCustomerAcquisition.maximizeConversionValue') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.startDate')" align="center" width="140"
                prop="begin_date">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.startTime')" align="center" width="140"
                prop="begin_time">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.endDate')" align="center" width="140"
                prop="end_date">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.endTime')" align="center" width="140"
                prop="end_time">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.deliveryBudget')" align="center" width="140"
                prop="day_price">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.numberOfDaysForDuration')" align="center"
                width="140" prop="day">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.totalBudget')" align="center" width="140"
                prop="total_price">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.totalCost')" align="center" width="140"
                prop="total_amount">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.country')" align="center" width="140"
                prop="push_country">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.launchCity')" align="center" width="140"
                prop="push_address">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.ageOfTheTargetGroup')" align="center"
                width="140" prop="person_age">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.genderOfTheTargetGroup')" align="center"
                width="140" prop="person_sex">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.language')" align="center" width="140"
                prop="language">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.adHeadline')" align="center" width="140"
                prop="adv_title" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.descriptionOfTheAd')" align="center" width="140"
                prop="adv_description" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.advertisingImages')" align="center" width="140"
                prop="adv_image">
                <template slot-scope="scope">
                  <el-image style="width: 44px; height: 44px" :src="scope.row.coverUrl[0]"
                    :preview-src-list="scope.row.coverUrl">
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.theUrlOfTheAdvertisingWebsite')" align="center"
                width="140" prop="adv_web" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <a :href="scope.row.adv_web" target="_blank" rel="noopener noreferrer">{{ scope.row.adv_web }}</a>
                </template>
              </el-table-column>
              <!-- <el-table-column :label="$t('eCommerceCustomerAcquisition.whatsAppContacts')" align="center" width="140"
                prop="whatsapp">
              </el-table-column> -->
              <el-table-column :label="$t('eCommerceCustomerAcquisition.creationTime')" align="center" width="140"
                prop="createtime">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.updated')" align="center" width="140"
                prop="updatetime">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.operation')" align="center" fixed="right"
                width="180">
                <template slot-scope="scope">
                <el-button @click="editorForm(scope.row)" >
                  {{ $t('eCommerceCustomerAcquisition.operationbtn1') }}
                </el-button>
                <el-button @click="activeForm(scope.row)" >
                  {{ $t('eCommerceCustomerAcquisition.operationbtn2') }}
                </el-button>
              </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totals">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 广告详情信息 -->
    <el-drawer
      :title="$t('eCommerceCustomerAcquisition.title2')"
      :visible.sync="drawer"
      :direction="direction"
      size="35%"
      :before-close="handledrawerClose">
      <div class="detail_box">
        <div class="form_adv">
          <el-divider>{{ $t('eCommerceCustomerAcquisition.infoContent') }}</el-divider>
          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.infoContentlabel1') }}</div>
          <div class="form_adv_content">
            <el-tag v-if="infoContent.status_run == 0" type="warning">{{ $t('eCommerceCustomerAcquisition.didnTStart') }}</el-tag>
            <el-tag v-if="infoContent.status_run == 1" type="warning">{{ $t('eCommerceCustomerAcquisition.infoContentstatus1') }}</el-tag>
            <el-tag v-if="infoContent.status_run == 2" type="warning">{{ $t('eCommerceCustomerAcquisition.infoContentstatus2') }}</el-tag>
            <el-tag v-if="infoContent.status_run == 3" type="warning">{{ $t('eCommerceCustomerAcquisition.infoContentstatus3') }}</el-tag>
            <el-tag v-if="infoContent.status_run == -1" type="warning">{{ $t('eCommerceCustomerAcquisition.infoContentstatus4') }}</el-tag>
          </div>
          <el-divider>{{ $t('eCommerceCustomerAcquisition.infoContentlabel2') }}</el-divider>
          <!-- 广告部分 -->
          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.serveYourWebsite') }}</div>
          <div class="form_adv_content">
            <a href="https://www.iconfont.cn/" target="_blank" rel="noopener noreferrer">{{ infoContent.adv_web }}</a>
          </div>
          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.adHeadline') }}</div>
          <div class="form_adv_content">
            <div class="contnet_input richtext">{{ infoContent.adv_title }}</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.descriptionOfTheAd') }}</div>
          <div class="form_adv_content">
            <div class="contnet_input richtext">
              {{ infoContent.adv_description }}
            </div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.advertisingImages') }}</div>
          <div class="form_adv_content">
            <el-image style="width: 100px; height: 100px" v-for="(d,index) in fileList" :src="d.url" :key="index" :preview-src-list="[d.url]">
            </el-image>
          </div>
          <el-divider>{{ $t('eCommerceCustomerAcquisition.infoContentlabel3') }}</el-divider>
          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.des5') }}</div>
          <div class="form_adv_content">
            <div v-if="infoContent.push_type == 1" class="contnet_input">{{ $t('eCommerceCustomerAcquisition.infoContentcontent1') }}</div>
            <div v-if="infoContent.push_type == 2" class="contnet_input">{{ $t('eCommerceCustomerAcquisition.infoContentcontent2') }}</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.theTypeOfImpression') }}</div>
          <div class="form_adv_content">
            <div v-if="infoContent.show_type == 1" class="contnet_input">{{ $t('eCommerceCustomerAcquisition.infoContentcontent3') }}</div>
            <div v-if="infoContent.show_type == 2" class="contnet_input">{{ $t('eCommerceCustomerAcquisition.infoContentcontent4') }}</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.targetStrategy') }}</div>
          <div class="form_adv_content">
            <div v-if="infoContent.show_type == 1" class="contnet_input">{{ $t('eCommerceCustomerAcquisition.infoContentcontent5') }}</div>
            <div v-if="infoContent.show_type == 2" class="contnet_input">{{ $t('eCommerceCustomerAcquisition.infoContentcontent6') }}</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.startDate') }}</div>
          <div class="form_adv_content">
            <div class="contnet_input">{{ infoContent.begin_time }}</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.endDate') }}</div>
          <div class="form_adv_content">
            <div class="contnet_input">{{ infoContent.end_time }}</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.totalBudget') }}</div>
          <div class="form_adv_content">
            <div class="contnet_input">{{ infoContent.total_amount }}$</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.country') }}</div>
          <div class="form_adv_content">
            <div class="contnet_input">{{ infoContent.push_address }}</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.ageOfTheTargetGroup') }}</div>
          <div class="form_adv_content">
            <div class="contnet_input">{{ infoContent.person_age }}</div>
          </div>

          <div class="form_adv_label">{{ $t('eCommerceCustomerAcquisition.language') }}</div>
          <div class="form_adv_content">
            <div class="contnet_input">{{ infoContent.language }}</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </el-main>
</template>

<script>
import { put, signatureUrl, getFileNameUUID } from "@/utils/alioss.js";
export default {
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      totals: 0,
      name: "广告任务",
      selelctRechargeStatusVal: "",
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      dataTimes: [],
      labelPosition: "right",
      statusVal: "",
      tableData: [],
      dialogVisible: false, // 广告申请弹窗
      modalStatus: 1, // 申请广告流程
      dialogImageUrl: "",
      dialogImageVisible: false,
      fileList: [], // 文件列表
      query: "", // 广告查询参数
      service_adv: "", // 广告手续费
      formStatus: "add", //
      infoContent: {
        adv_sn: "", // 口令-识别
        push_type: "", // 投放模式 1.流量 2.销量
        show_type: "", // 展示广告 1.网站 2.信息应用
        target: "", // 成绩目标 1.转化量最大化 2.转化价值最大化
        day: 5, // 持续几天
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        begin_time: "", // 开始时间
        end_time: "", // 结束时间
        day_price: "", // 单日预算
        push_country: "", // 投放国国家
        push_address: "", // 投放地区 国家 城市 支持多个
        person_age: "", // 目标群体年龄范围
        person_sex: "", // 目标群体性别范围
        language: "英语", // 语言 默认英语
        adv_title: "", // 广告标题
        adv_description: "", // 广告描述
        adv_image: "", // 广告图片/视频地址
        adv_web: "", // 广告网站URL
        whatsapp: "", // WhatsApp联系方式
      },
      userUsd: "", // 用户余额
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
    };
  },
  computed: {
    total_adv: function () {
      if (
        this.infoContent.day &&
        this.service_adv &&
        this.infoContent.day_price
      ) {
        const adv = parseFloat(this.service_adv.replaceAll("%", ""));
        const total =
          this.infoContent.day_price * this.infoContent.day +
          (adv / 100) * this.infoContent.day_price * this.infoContent.day;
        if (total > this.userUsd) {
          this.$message.error({
            message: "余额不足",
            type: "error",
            duration: 3000,
          });
        }
        return total;
      } else {
        return "";
      }
    },
  },
  methods: {
    // 广告商接单
    activeForm(row){
    console.log("row ==> ", row);
    this.$confirm(this.$t('eCommerceCustomerAcquisition.modalcontent'), this.$t('eCommerceCustomerAcquisition.modallabel'), {
          confirmButtonText: this.$t('eCommerceCustomerAcquisition.modalbtn1'),
          cancelButtonText: this.$t('eCommerceCustomerAcquisition.modalbtn2'),
          type: 'warning'
        }).then(() => {
          let params = {
            id: row.id,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/takeAdvert", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(res => {
        if(res.data.code == 200){
          this.$message.success(res.data.msg)
          this.searchData()
        }else{
          this.$message.error(res.data.msg)
        }
      })
        }).catch(() => {
          
        });
    },
    handledrawerClose(){
      this.drawer = false;
    },
    // 天数更新的时候判断时间是否需要更新
    dayChange(val) {
      console.log("val: ", val);
      console.log("this.infoContent.begin_date: ", this.infoContent.begin_date);

      if (val && this.infoContent.begin_date) {
        console.log(222);
        this.changeDate(this.infoContent.begin_date);
      }
    },
    // 投放模式
    selectModul(value) {
      this.infoContent.push_type = value;
    },
    // 改变开始日期
    changeDate(value) {
      if (!this.infoContent.day) {
        this.$message.error({
          message: "请先选择天数",
          type: "error",
          duration: 3000,
        });
        this.infoContent.end_date = "";
        return;
      }
      if (value) {
        console.log("value: ", value);
        let d = new Date(value);
        console.log("d: ", d);
        d.setDate(d.getDate() + parseInt(this.infoContent.day));
        let year = d.getFullYear(); // 获取年份
        let month = d.getMonth() + 1; // 获取月份（注意月份是从0开始计数的，所以要加1）
        let day = d.getDate(); // 获取日期

        // 格式化月份和日期为两位数
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        let formattedDate = year + "-" + month + "-" + day;
        this.infoContent.end_date = formattedDate;
      }
    },
    // 改变时间
    changeTime(value) {
      if (value) {
        this.infoContent.end_time = value;
      }
    },
    // 编辑表单
    editorForm(row) {
      console.log("row ==> ", row);
      let params = {
        id: row.id,
        adv_sn: row.adv_sn,
      }
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/advertDetail", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(res => {
        console.log("res ==> ", res.data.data.data);
        const item = res.data.data.data;
        this.infoContent = item;
        let fileList = JSON.parse(row.adv_image);
        this.fileList = fileList;
        this.drawer = true
      })
      // 处理文件
      // let fileList = JSON.parse(row.adv_image);
      // this.fileList = fileList;
      // this.infoContent = row;
      // this.drawer = true;
      // this.formStatus = "editor";
    },
    viewResultImg() {
      this.$refs.previewImg.showViewer = true;
    },
    // 生成广告口令
    generateRandomString(length) {
      const characters =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
      }
      return result;
    },
    getFileName(name) {
      return name.substring(name.lastIndexOf(".")); //.txt
    },
    uploadFile(file) {
      console.log("this.fileList.length: ", this.fileList.length);
      if (this.fileList.length == 3) {
        this.$message({
          type: "warning",
          message: "最多只能上传3张图片",
        });
        return;
      }
      return file;
    },
    handleUpload(option) {
      var obj = option.file.name;
      var index = obj.lastIndexOf(".");
      obj = obj.substring(index, obj.length);
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = getFileNameUUID() + this.getFileName(option.file.name);
      put("/ADIamge/" + option.file.name, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        signatureUrl("/ADIamge/" + option.file.name).then((resSign) => {
          this.fileList.push({
            name: objName,
            url: resSign.slice(0, resSign.indexOf("?")),
            status: "success",
          });
        });
      });
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    closeDialog() {
      this.fileList = [];
      this.infoContent = {
        adv_sn: "", // 口令-识别
        push_type: "", // 投放模式 1.流量 2.销量
        show_type: "", // 展示广告 1.网站 2.信息应用
        target: "", // 成绩目标 1.转化量最大化 2.转化价值最大化
        day: 5, // 持续几天
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        begin_time: "", // 开始时间
        end_time: "", // 结束时间
        day_price: "", // 单日预算
        push_address: "", // 投放地区 国家 城市 支持多个
        person_age: "", // 目标群体年龄范围
        person_sex: "", // 目标群体性别范围
        language: "英语", // 语言 默认英语
        adv_title: "", // 广告标题
        adv_description: "", // 广告描述
        adv_image: "", // 广告图片/视频地址
        adv_web: "", // 广告网站URL
        whatsapp: "", // WhatsApp联系方式
      };
      this.modalStatus = 1;
      this.formStatus = "add";
      this.dialogVisible = false;
    },
    // 打开弹窗
    openForm() {
      this.getHui();
      this.infoContent = {
        adv_sn: "", // 口令-识别
        push_type: "", // 投放模式 1.流量 2.销量
        show_type: "", // 展示广告 1.网站 2.信息应用
        target: "", // 成绩目标 1.转化量最大化 2.转化价值最大化
        day: 5, // 持续几天
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        begin_time: "", // 开始时间
        end_time: "", // 结束时间
        day_price: "", // 单日预算
        push_address: "", // 投放地区 国家 城市 支持多个
        person_age: "", // 目标群体年龄范围
        person_sex: "", // 目标群体性别范围
        language: "英语", // 语言 默认英语
        adv_title: "", // 广告标题
        adv_description: "", // 广告描述
        adv_image: "", // 广告图片/视频地址
        adv_web: "", // 广告网站URL
        whatsapp: "", // WhatsApp联系方式
      };
      this.dialogVisible = true;
    },
    changeUsd(val) {
      console.log("val: ", val);
      if (parseInt(val) < 5) {
        this.$message.warning("投放预算最低单日5美元!");
        this.infoContent.day_price = "5";
      }
    },
    // 获取用户信息
    getUser() {
      const result = this.$http.post(
        "/api/v1/getuserinfos",
        {},
        {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        }
      );
      result.then((res) => {
        this.service_adv = res.data.data.service_adv;
        this.service_adv = this.service_adv + "%";
      });
    },
    // 上一步
    lastStep() {
      this.modalStatus--;
    },
    // 下一步
    nectStep() {
      if (
        this.modalStatus == 2 &&
        this.infoContent.begin_time &&
        this.infoContent.end_time &&
        this.infoContent.day_price &&
        this.infoContent.push_address &&
        this.infoContent.person_age &&
        this.infoContent.person_sex &&
        this.infoContent.language
      ) {
        this.modalStatus++;
        return;
      }
      if (
        this.modalStatus == 1 &&
        this.infoContent.push_type &&
        this.infoContent.show_type &&
        this.infoContent.target
      ) {
        this.modalStatus++;
        return;
      }
      this.$message({
        message: "请填写完整信息",
        type: "warning",
      });
    },
    // 编辑广告
    editorAD() {
      if (
        this.modalStatus == 3 &&
        this.infoContent.adv_title &&
        this.infoContent.adv_description &&
        this.infoContent.adv_web &&
        this.infoContent.person_age &&
        this.infoContent.whatsapp &&
        this.fileList.length > 0
      ) {
        // 生成口令
        this.infoContent.adv_sn = this.generateRandomString(10);
        // 创建文件
        this.infoContent.adv_image = JSON.stringify(this.fileList);
        // 申请广告
        let params = this.infoContent;
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/reApplyAdvert", params, {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.dialogVisible = false;
            this.searchData();
          },
          (error) => {
            console.log(error);
          }
        );
        return;
      }
      this.$message({
        message: "请填写完整信息",
        type: "warning",
      });
    },
    // 申请广告
    createAD() {
      if (
        this.modalStatus == 3 &&
        this.infoContent.adv_title &&
        this.infoContent.adv_description &&
        this.infoContent.adv_web &&
        this.infoContent.person_age &&
        this.infoContent.whatsapp &&
        this.fileList.length > 0
      ) {
        // 生成口令
        this.infoContent.adv_sn = this.generateRandomString(10);
        // 创建文件
        this.infoContent.adv_image = JSON.stringify(this.fileList);
        // 申请广告
        let params = this.infoContent;
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/applyAdvert", params, {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.dialogVisible = false;
            this.searchData();
          },
          (error) => {
            console.log(error);
          }
        );
        return;
      }
      this.$message({
        message: "请填写完整信息",
        type: "warning",
      });
    },
    getHui() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getHuiUsdcharInfo", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.userUsd = res.data.data.usd;
            // this.form_hw.user = res.data.data.username;
            // this.form_hw.usd = res.data.data.usd;
            // this.form_hw.service_char = res.data.data.service_char;
            // this.form_hw.usdlv = res.data.data.usdlv;
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 搜索
    searchData(rf) {
      if (rf == "1") {
        this.currentPage = 1;
        this.pageSize = 100;
        this.query = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        adv_sn: this.query,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getWaitTakeAdvertList", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.totals = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
            this.tableData[i].updatetime = this.getTime(
              res.data.data.data[i].updatetime
            );
            const file = JSON.parse(res.data.data.data[i].adv_image);
            this.tableData[i].coverUrl = file.map((item) => item.url);

            const file_result = res.data.data.data[i].result_image
              ? JSON.parse(res.data.data.data[i].result_image)
              : [];
            this.tableData[i].resultUrl = file_result.map((item) => item.url);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 格式化时间
    getTime() {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts / 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getCardDetails();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCardDetails();
    },
  },
  created() {
    this.searchData();
    this.getUser();
  },
  mounted() { },
};
</script>

<style lang="less" scoped>
// /deep/ .el-select .el-input.is-focus .el-input__inner {
//   border-color: #ff0080;
// }

// /deep/ .el-select .el-input__inner:focus {
//   border-color: #ff0080;
// }

// /deep/ .el-input--prefix {
//   margin-right: 0% !important;
// }

// /deep/ .el-input__inner:focus {
//   border-color: #ff0080;
// }

/deep/ .el-dialog {
  border-radius: 0px;
}

/deep/ .el-dialog__body {
  padding: 12px 20px;
}

.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}

.user_box {
  .header_btn_group {
    display: flex;
    align-items: center;

    .btn_box_item {
      border-radius: 4px;
      margin-left: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      width: 120px;
      height: 38px;
      transition: all 2ms ease-out;
      background-image: linear-gradient(310deg, #141727, #3a416f);
      box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
        0 2px 4px -1px rgba(0, 0, 0, 0.07);
    }

    .btn_box_item:hover {
      transform: translateY(-1.5px);
    }

    .btn_box_item:active {
      transform: translateY(1.5px);
    }

    .btn_next {
      background-image: linear-gradient(310deg, #7928ca, #ff0080);
      color: #fff;
    }

    .btn_add {
      margin-left: 1%;
      position: relative;
      width: 120px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      /* 强制大写 */
      text-decoration: none;
      font-family: sans-serif;
      box-sizing: border-box;
      background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
      background-size: 400%;
      border-radius: 4px;
      z-index: 1;
    }

    .btn_add:hover {
      animation: animate 8s linear infinite;
    }

    @keyframes animate {
      0% {
        background-position: 0%;
      }

      100% {
        background-position: 400%;
      }
    }

    .btn_add:before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      z-index: -1;
      background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
      background-size: 300%;
      border-radius: 40px;
      opacity: 0;
      transition: 0.5s;
    }

    .btn_add:hover:before {
      filter: blur(20px);
      /*模糊效果*/
      opacity: 1;
      animation: animate 8s linear infinite;
    }
  }
}


.second_card-modal {
  width: 100%;

  &_title {
    margin-bottom: 12px;
    font-size: 14px;

    img {
      width: 22px;
      height: 22px;
    }
  }

  &_time {
    margin-bottom: 16px;
  }

  &_input {
    margin-bottom: 16px;
  }

  &_cover {
    border: 1px solid orange;
    border-radius: 8px;
    margin-bottom: 12px;

    img {
      width: 100%;
      height: 180px;
    }
  }
}

.three_card-modal {
  width: 100%;

  &_title {
    margin-bottom: 4px;
    font-size: 14px;
  }

  &_time {
    margin-bottom: 16px;
  }

  &_input {
    margin-bottom: 16px;
  }

  &_cover {
    // border: 1px solid orange;
    border-radius: 8px;
    margin-bottom: 12px;

    img {
      width: 100%;
      height: 180px;
    }

    .upload-demo {
      width: 100%;
    }
  }
}

.btn_box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  .btn_box_item {
    border-radius: 2px;
    margin-left: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    width: 130px;
    height: 38px;
    transition: all 2ms ease-out;
    background-image: linear-gradient(310deg, #141727, #3a416f);
    box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
      0 2px 4px -1px rgba(0, 0, 0, 0.07);
  }

  .btn_box_item:hover {
    transform: translateY(-1.5px);
  }

  .btn_box_item:active {
    transform: translateY(1.5px);
  }

  .btn_next {
    background-image: linear-gradient(310deg, #7928ca, #ff0080);
    color: #fff;
  }
}

.openRunImage_box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7928ca;

  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
}

.detail_box{
  padding: 12px 20px;
  height: 100%;
  overflow-y: auto;
  .form_adv{
    width: 100%;
    height: auto;
    .form_adv_label{
      font-size: 15px;
      font-family: 'pingfang';
      font-weight: 600;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }
    .form_adv_content{
      margin-bottom: 20px;
      .contnet_input{
        box-sizing: border-box;
        width: 100%;
        height: 35px;
        border: 1px solid #ccc;
        border-radius: 6px;
        line-height: 35px;
        padding-left: 8px;
        font-size: 13px;
        background: #e1dede;
        color: #4f4c4c;
      }
      .richtext{
        overflow-y: auto;
        min-height: 105px;
        max-height: 140px;
      }
    }
  }
}
</style>
