<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 充值渠道 -->
            <!-- <el-select v-model="czVal" placeholder="审核状态" clearable
              style="width: 8%; margin-right: 10px; margin-left: 10px">
              <el-option v-for="item in selectCz" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
            <el-button style="margin-bottom: 20px; margin-left: 10px;width: 120px;" icon="el-icon-plus" @click="addAppModal()">
              新增
            </el-button>
            <!-- <el-button v-no-more-click icon="el-icon-refresh" @click="getDataList('rf')">刷 新
            </el-button> -->
            <br />
            <!-- <br /> -->
            <!-- 公告列表 -->
            <el-table :data="DataList" style="width: 100%" size="small" height="660" max-height="800">
              <!-- id -->
              <el-table-column prop="id" align="center" label="id" width="120">
              </el-table-column>
              <!-- 真实姓名 -->
              <el-table-column prop="appversion" align="center" label="版本号" width="120" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="createtime" align="center" label="上传时间" width="180" :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 真实姓名 -->
              <el-table-column prop="url" align="center" label="文件url"  :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 企业类型 -->
              <el-table-column prop="description" align="center" label="描述" 
                :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <el-button  type="primary" @click="delApp(scope.row,1)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="noticeTotal">
            </el-pagination>
          </div>
          <!-- 审核认证 -->
          <el-dialog custom-class="ls" title="app上传" :visible.sync="newCardsdialogVisible" width="30%">
            <el-form label-position="top" label-width="80px" >
              <!-- 标题 -->
              <el-form-item label="版本号">
                <el-input v-model="form_app.version"  placeholder="请输入版本号" type="number"></el-input>
              </el-form-item>
              <!-- 标题 -->
              <el-form-item label="上传apk">
                <el-input v-model="form_app.url"  placeholder="请输入apk文件链接" ></el-input>
                <!-- <el-upload
                class="upload-demo"
                accept=".apk"
                :http-request="handleUpload"
                :limit="1"
                :before-upload="uploadFile"
                :file-list="fileList"
                action="#"
                :on-remove="handleRemove"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload> -->
              </el-form-item>
              <!-- 内容 -->
              <el-form-item label="升级描述">
                <el-input type="textarea" v-model="form_app.description" :autosize="{ minRows: 6, maxRows: 50 }"></el-input>
              </el-form-item>
            </el-form>
            <div class="form_foot">
              <el-button @click="createApp(), (newCardsdialogVisible = false)" v-no-more-click>确
                定</el-button>
              <el-button @click="newCardsdialogVisible = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { put, signatureUrl, getFileNameUUID } from "@/utils/alioss.js";
export default {
  data() {
    return {
      name: "APP升级管理",
      noticeTotal: 0,
      refuse_msg:'',
      selectId: "",
      status:0,
      DataList: [],
      czVal: "", // 查询参数
      selectCz: [
        {
          value: 1,
          label: "申请中",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      newCardsdialogVisible: false, // 审核窗口
      fileList: [],
      form_app:{
        version:'',
        description:'',
        url:''
      }, // app表单
    };
  },
  methods: {
    addAppModal(){
      this.form_app = {}
      this.newCardsdialogVisible = true
    },
    delApp(item){
      console.log("item ==> ", item.id);
      this.$confirm("此操作将删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/deleteAppVersionLog", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(res => {
            console.log("res ==> ", res);
            this.getDataList()
          })
        })
        .catch(() => {
          
        })
    },
    createApp(){
      let params = {
        appversion: this.form_app.version,
        description: this.form_app.description,
        url: this.form_app.url
      };
      // if(this.fileList.length == 0){
      //   this.$message.error('请上传apk文件')
      //   return
      // }
      // params.url = this.fileList[0].url
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/addAppVersionLog", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(res => {
        console.log("res ==> ", res);
        if(res.data.code == 200){
          this.$message.success(res.data.msg)
        }else{
          this.$message.error(res.data.msg)
        }
        this.getDataList()
        this.newCardsdialogVisible = false
      })
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    // 获取数据
    getDataList(flag) {
      if (flag && flag == "rf") {
        this.currentPage = 1;
        this.pageSize = 100;
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        status: this.czVal,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/appVersionLogList", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.DataList = res.data.data.data || [];
          this.noticeTotal = res.data.data.total;
          for (let i = 0; i < this.DataList.length; i++) {
            this.DataList[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    uploadFile(file) {
      if (this.fileList.length == 1) {
        this.$message({
          type: "warning",
          message: "最多只能上传1个",
        });
        return;
      }
      return file;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    getFileName(name) {
      return name.substring(name.lastIndexOf(".")); //.txt
    },
    // 阿里上传oss
    handleUpload(option) {
      var obj = option.file.name;
      var index = obj.lastIndexOf(".");
      obj = obj.substring(index, obj.length);
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = getFileNameUUID() + this.getFileName(option.file.name);
      put("/appApk/" + option.file.name, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        signatureUrl("/appApk/" + option.file.name).then((resSign) => {
          this.fileList.push({
            name: objName,
            url: resSign.slice(0, resSign.indexOf("?")),
            status: "success",
          });
        });
      });
    },
  },
  created() {
    this.getDataList();
  },
  mounted() { },
};
</script>

<style scoped>
.form_foot {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
</style>
