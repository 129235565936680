<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('businessHistory.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('businessHistory.cardManagement') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="user_box">
            <el-form :model="form_balance" :rules="form_balanceRules" ref="form_balanceFormRef"
              :label-position="labelPosition">
              <!-- 输入卡号 -->
              <el-button icon="el-icon-plus" @click="openConfig">{{ $t('biiAddress.create') }}</el-button>
            </el-form>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table :data="tableData" style="width: 100%" height="620" max-height="800" size="small"
              highlight-current-row>
              <el-table-column :label="$t('biiAddress.emails')" prop="emails" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.births')" prop="births" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.first_name')" prop="first_name" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.last_name')" prop="last_name" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.mobile')" prop="mobile" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.mobile_prefix')" prop="mobile_prefix" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.nationality_country_code')" prop="nationality_country_code" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.residential_address')" prop="residential_address" width="250" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.residential_city')" prop="residential_city" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.residential_country_code')" prop="residential_country_code" width="350">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.residential_postal_code')" prop="residential_postal_code" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.residential_state')" prop="residential_state" width="150">
              </el-table-column>
              <el-table-column :label="$t('biiAddress.operation')" width="200">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="editorConfig(scope.row)">
                    {{ $t('biiAddress.editor') }}
                  </el-button>
                  <el-button size="mini" type="danger" @click="removeConfig(scope.row)">
                    {{ $t('biiAddress.remove') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
          <!-- 新增配置 -->
          <el-dialog custom-class="ls" :title="modalTitle" :close-on-click-modal="false"  :visible.sync="dialogXz" width="40%" ref="dialog__wrapper"
            top="5vh">
            <el-form ref="ruleForm" style="margin: 0 12px;"  :label-position="labelPosition" label-width="140px"
              :model="form_xz" @close="dialogXz = false, this.form_xz = {}" :rules="form_xzFormRef">
              <el-form-item :label="$t('biiAddress.emails')" prop="emails">
                <el-input @input="handleInput($event,'emails')" v-model="form_xz.emails" :placeholder="$t('biiAddress.emails_rule')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('biiAddress.births')" prop="births">
                <el-date-picker v-model="form_xz.births" style="width: 48%;" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                :placeholder="$t('biiAddress.births_rule')">
                </el-date-picker>
                <!-- <el-input v-model="form_xz.births"></el-input> -->
              </el-form-item>
              <div style="display: flex;justify-content: space-between;">
                <el-form-item :label="$t('biiAddress.first_name')" style="width: 48%;" prop="first_name">
                  <el-input @input="handleInput($event,'first_name')" v-model="form_xz.first_name" :placeholder="$t('biiAddress.first_name_rule')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('biiAddress.last_name')" style="width: 48%;" prop="last_name">
                  <el-input @input="handleInput($event,'last_name')" v-model="form_xz.last_name" :placeholder="$t('biiAddress.last_name_rule')"></el-input>
                </el-form-item>
              </div>
              <el-form-item :label="$t('biiAddress.mobile')" prop="mobile">
                <el-input @input="handleInput($event,'mobile')" v-model="form_xz.mobile" :placeholder="$t('biiAddress.mobile_rule')+'( 9203298328 )'"></el-input>
              </el-form-item>
              <el-form-item :label="$t('biiAddress.mobile_prefix')" prop="mobile_prefix">
                <el-input @input="handleInput($event,'mobile_prefix')" v-model="form_xz.mobile_prefix" :placeholder="$t('biiAddress.mobile_prefix_rule')+'( 01 )'"></el-input>
              </el-form-item>
              <el-form-item :label="$t('biiAddress.nationality_country_code')" prop="nationality_country_code">
                <el-input @input="handleInput($event,'nationality_country_code')" v-model="form_xz.nationality_country_code" :placeholder="$t('biiAddress.nationality_country_code_rule')+'( US )'"></el-input>
              </el-form-item>
              <el-form-item :label="$t('biiAddress.residential_address')" prop="residential_address">
                <el-input @input="handleInput($event,'residential_address')" v-model="form_xz.residential_address" :placeholder="$t('biiAddress.residential_address_rule')+'( 5 W 5th St Room 34027 )'"></el-input>
              </el-form-item>
              <el-form-item :label="$t('biiAddress.residential_city')" prop="residential_city">
                <el-input @input="handleInput($event,'residential_city')" v-model="form_xz.residential_city" :placeholder="$t('biiAddress.residential_city_rule')+'( Los Angeles )'"></el-input>
              </el-form-item>
              <el-form-item :label="$t('biiAddress.residential_country_code')" prop="residential_country_code">
                <el-input @input="handleInput($event,'residential_country_code')" v-model="form_xz.residential_country_code" :placeholder="$t('biiAddress.residential_country_code_rule')+'( US )'"></el-input>
              </el-form-item>
              <el-form-item :label="$t('biiAddress.residential_postal_code')" prop="residential_postal_code">
                <el-input @input="handleInput($event,'residential_postal_code')" v-model="form_xz.residential_postal_code" :placeholder="$t('biiAddress.residential_postal_code_rule')+'( 90013 )'"></el-input>
              </el-form-item>
              <el-form-item :label="$t('biiAddress.residential_state')" prop="residential_state">
                <el-input @input="handleInput($event,'residential_state')" v-model="form_xz.residential_state" :placeholder="$t('biiAddress.residential_state_rule')+'( California )'"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogXz = false">
                {{ $t('biiAddress.cancel') }}
              </el-button>
              <el-button @click="addConfig" v-no-more-click>
                {{ $t('biiAddress.save') }}
              </el-button>
            </span>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>

export default {
  data() {
    return {
      modalTitle: this.$t('biiAddress.addFormTitle'),
      gshareusdmoney: "",
      pshareusdmoney: "",
      cny: "",
      usd: "",
      name: this.$t('biiAddress.pageTitle'),
      dialogXz: false, // 新增配置
      loadingBtn: false,
      form_xz: {},
      total: 0,
      labelPosition: "top",
      selectVlues: "",
      selelctRechargeStatusVal: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      dataTimes: [],
      form_balance: {
        cardNo: "",
      },
      form_balanceRules: {},
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      form_xzFormRef: {
        emails: [
          { required: true, message: this.$t('biiAddress.emails_rule'), trigger: "blur" },
          { type: "email", message: this.$t('biiAddress.emails_rule'), trigger: "blur" },
        ],
        births: [
          { required: true, message: this.$t('biiAddress.births_rule'), trigger: "blur" },
        ],
        first_name: [
          { required: true, message: this.$t('biiAddress.first_name_rule'), trigger: "blur" }
        ],
        last_name: [
          { required: true, message: this.$t('biiAddress.last_name_rule'), trigger: "blur" }
        ],
        mobile: [
          { required: true, message: this.$t('biiAddress.mobile_rule'), trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: this.$t('biiAddress.mobile_rule'), trigger: "blur" },
        ],
        mobile_prefix: [
          { required: true, message: this.$t('biiAddress.mobile_prefix_rule'), trigger: "blur" },
        ],
        nationality_country_code: [
          { required: true, message: this.$t('biiAddress.nationality_country_code_rule'), trigger: "blur" },
        ],
        residential_address: [
          { required: true, message: this.$t('biiAddress.residential_address_rule'), trigger: "blur" }
        ],
        residential_city: [
          { required: true, message: this.$t('biiAddress.residential_city_rule'), trigger: "blur" }
        ],
        residential_country_code: [
          { required: true, message: this.$t('biiAddress.residential_country_code_rule'), trigger: "blur" }
        ],
        residential_postal_code: [
          { required: true, message: this.$t('biiAddress.residential_postal_code_rule'), trigger: "blur" }
        ],
        residential_state: [
          { required: true, message: this.$t('biiAddress.residential_state_rule'), trigger:"blur" }
        ]
      }
    };
  },
  methods: {
    handleInput(event,type) {
      const chineseRegex = /[\u4e00-\u9fa5]/;
      if (chineseRegex.test(event)) {
        this.form_xz[type] = ''
        this.$message.warning('禁止输入中文')
      }
    },
    editorConfig(item) {
      this.modalTitle = this.$t('biiAddress.editorFormTitle')
      this.form_xz = JSON.parse(JSON.stringify(item))
      this.dialogXz = true
    },
    removeConfig(item) {
      this.$confirm(this.$t('biiAddress.tip2'), this.$t('biiAddress.tip3'), {
        confirmButtonText: this.$t('biiAddress.comfirm'),
        cancelButtonText: this.$t('biiAddress.cancel'),
        type: 'warning'
      }).then(() => {
        let params = {
          id: item.id
        };
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/deleteDogBill", params, {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.$message({
              type: 'success',
              message: this.$t('biiAddress.tip4')
            });
            this.searchUser()
          },
          (error) => {
            console.log(error);
          }
        );
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('biiAddress.tip5')
        });
      });
    },
    openConfig() {
      this.modalTitle = this.$t('biiAddress.addFormTitle')
      this.form_xz = {}
      this.dialogXz = true
    },
    addConfig() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = this.form_xz;
          params = this.$lmjm(params);
          this.loadingBtn = true
            const result = this.$http.post("/api/v1/setDogBill", params, {
              headers: {
                token: window.localStorage.getItem("token"),
              },
            });
            result.then(
              (res) => {
                if (res.data.code == 200) {
                  this.form_xz = {}
                  this.dialogXz = false
                  this.loadingBtn = false
                  this.$message({
                    message: res.data.msg,
                    type: 'success'
                  })
                  this.searchUser()
                } else {
                  this.$message.error(res.data.msg)
                }
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.$message.error(this.$t('biiAddress.tip1'))
        }
      })

    },
    getCommonNum() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCommonNum", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cny = res.data.data.cnycount;
          this.usd = res.data.data.usdcount;
          this.pshareusdmoney = res.data.data.pshareusdmoney;
          this.gshareusdmoney = res.data.data.gshareusdmoney;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 搜索
    searchUser() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getDogBill", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data;
          this.total = this.tableData.length;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.searchUser();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchUser();
    },
  },
  created() {
    this.searchUser();
    this.getCommonNum();
  },
  mounted() { },
};
</script>

<style scoped>
.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}

/deep/ .el-form-item {
  margin-bottom: 12px;
}
</style>
