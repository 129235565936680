import { render, staticRenderFns } from "./getDivideInfoData.vue?vue&type=template&id=b16dfd08&scoped=true"
import script from "./getDivideInfoData.vue?vue&type=script&lang=js"
export * from "./getDivideInfoData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b16dfd08",
  null
  
)

export default component.exports